import React, { useRef, useState, useEffect } from 'react';
import {
  ChatBubbleCON,
  ChatCON,
  CustomerChatHeader,
  CustomerChatHeaderAvatar,
  CustomerChatHeaderName,
  CustomerChatMessages,
  CustomerChatProduct,
  CustomerChatProductImage,
  CustomerChatProductInfo,
  CustomerChatProductInfoCON,
  CustomerSupportChatCON,
  MarkChatClosedButtonExtraStyles
} from './style';
import { CustomBreadcrumb } from '../../../components/atoms/Breadcrumb';
import { DownArrow, LeftArrowTailIcon } from '../../../assets/Icons';
import ChatProductImage from '../../../assets/images/chat-product-image.png';
import { ChatBox } from '../../../components/molecules/ChatBox';
import useWebSocketChat, { ChatMessage } from '../../../hooks/useWebSocketChat';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../../components/atoms';
import { QUERY_KEYS } from '../../../store/keys';
import { useQuery } from 'react-query';
import { adminGetOrderDetails } from '../../../network/services/admin/order';
dayjs.extend(relativeTime);

const CustomerSupportChat = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('userId') || '';
  const orderRef = searchParams.get('orderRef') || '';
  const fullName = searchParams.get('fullName') || '';
  const { connected, messages, connect, sendMessage, disconnect, markChatAsClosed } = useWebSocketChat(
    userId,
    orderRef
  );

  const chatMessageInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  
  const orderRefString = orderRef?.startsWith('#') ? orderRef?.replace(/^#/, '%23') : `%23${orderRef}`;
  const { data } = useQuery([QUERY_KEYS.ORDER_DETAILS, orderRefString], adminGetOrderDetails, { enabled: !!orderRef });

  const handleSendMessage = () => {
    const message = chatMessageInputRef.current?.value.trim();
    if (message) {
      const chatMessage: ChatMessage = {
        content: message,
        senderId: process.env.REACT_APP_ADMIN_CHAT_NICKNAME as string,
        recipientId: userId,
        timestamp: new Date().toISOString()
      };
      sendMessage(chatMessage, orderRef);
      chatMessageInputRef.current!.value = '';
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const message = chatMessageInputRef.current?.value.trim();
    if (e.key === 'Enter' && message !== '') {
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    connect();
    return () => {
      if (connected) {
        disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // This will trigger scrolling whenever the messages array change

  return (
    <CustomerSupportChatCON>
      <CustomBreadcrumb firstLabel={LeftArrowTailIcon} secondLabel="Customer support" />
      <ChatCON>
        <CustomerChatHeader>
          <div className="customer-details">
            <CustomerChatHeaderAvatar />
            <CustomerChatHeaderName>
              <h3>{fullName}</h3>
              <span>Customer</span>
            </CustomerChatHeaderName>
          </div>
          <Button type="button" onClick={markChatAsClosed} extraStyles={MarkChatClosedButtonExtraStyles}>
            Mark chat as Closed
          </Button>
        </CustomerChatHeader>
        {data?.data && (
          <CustomerChatProduct>
            <CustomerChatProductInfoCON>
              {/* <CustomerChatProductImage src={ChatProductImage} /> */}
              <CustomerChatProductInfo>
                <span className="order-id">Order: {orderRef}</span>
                <h5 className="product-name">
                  Payment: {data?.data?.paymentStatus} - {data?.data?.paymentType}
                </h5>
                <span className="order-date">{dayjs(data?.data?.orderDate).format('DD/MM/YY, hh:mm a')}</span>
              </CustomerChatProductInfo>
            </CustomerChatProductInfoCON>
            <DownArrow className="more-details-icon" />
          </CustomerChatProduct>
        )}
        <CustomerChatMessages>
          {messages.map((message, index) => (
            <ChatBubbleCON key={index} isAdmin={message.senderId === process.env.REACT_APP_ADMIN_CHAT_NICKNAME}>
              {message.content}

              <time>{dayjs(message.timestamp).fromNow()}</time>
            </ChatBubbleCON>
          ))}
          <div ref={messagesEndRef} />
        </CustomerChatMessages>
        <ChatBox
          name="chat"
          chatMessageInputRef={chatMessageInputRef}
          onChange={() => {}}
          onKeyDown={handleKeyDown}
          handleSendMessage={handleSendMessage}
        />
      </ChatCON>
    </CustomerSupportChatCON>
  );
};

export default CustomerSupportChat;
