import { useQuery } from 'react-query';
import { getOrderDetails } from '../../../network/services/admin/order';
import { OrderDetailsCON, OrderDetailsContent, StatusCON } from './style';
import { GreenDot, OrangeDot } from '../../../assets/Icons';
import { formatDate } from '../../../utils/dateFormater';
import { formatCurrency } from '../../../utils/currencyFormater';
import { EmptyCon } from '../../../components/atoms';
import { CircularProgress } from '@mui/material';
import StatusDot from '../../../components/atoms/StatusDot';

export const OrderDetails = ({ id }: any) => {
  const { data, isFetchedAfterMount } = useQuery(['admin_order_details', { id }], getOrderDetails, { enabled: !!id });
  const orderDetails = data?.data || {};

  return (
    <div>
      {!isFetchedAfterMount && (
        <EmptyCon>
          <CircularProgress size={30} />
        </EmptyCon>
      )}
      {isFetchedAfterMount && (
        <OrderDetailsCON>
          <OrderDetailsContent>
            <p className="label"> Order ID</p>
            <p className="value">{orderDetails?.orderRef}</p>
          </OrderDetailsContent>
          <OrderDetailsContent>
            <p className="label">Vendor Name</p>
            <p className="value">{orderDetails?.orders?.[0].product.seller.name}</p>
          </OrderDetailsContent>
          <OrderDetailsContent>
            <p className="label">Number of Item</p>
            <p className="value">{orderDetails?.orderItemQuantity}</p>
          </OrderDetailsContent>
          <OrderDetailsContent>
            <p className="label">Shipping Fee</p>
            <p className="value">{formatCurrency(orderDetails?.shippingFee)}</p>
          </OrderDetailsContent>
          <OrderDetailsContent>
            <p className="label">Order Date</p>
            <p className="value">{formatDate(orderDetails?.orderDate)}</p>
          </OrderDetailsContent>
          <OrderDetailsContent>
            <p className="label">Delivery Type</p>
            <p className="value">{orderDetails?.deliveryMethod}</p>
          </OrderDetailsContent>
          <OrderDetailsContent>
            <p className="label"> Status</p>
            <StatusCON status={orderDetails?.status.toLowerCase()}>
              <StatusDot status={orderDetails?.status.toLowerCase()} /> <p>{orderDetails?.status}</p>
            </StatusCON>
          </OrderDetailsContent>
          <OrderDetailsContent>
            <p className="label">Delivery Status</p>
            <StatusCON status="Pending">
              <OrangeDot /> <p>N/A</p>
            </StatusCON>
          </OrderDetailsContent>
        </OrderDetailsCON>
      )}
    </div>
  );
};
