import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { useSetRecoilState } from 'recoil';
import { Button, Spacer } from '../../../components/atoms';
import { requestOtp, verifyOtp } from '../../..//network/services/auth';
// import { userIsLoggedIn } from '../../store/atoms/userAtom';
import { useCounter } from '../../../utils/counter';
import { AuthContainer, Line, OtpContainer } from '.././styles';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { apiResponse } from '../../../network/config/message.constants';

export const Otp = () => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const refs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ];

  const handleChange = (index: number, e: COMMONTYPES['inputChange']) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;

    setOtp(newOtp);

    // Focus on next input field
    if (e.target.value && index < 5) {
      refs[index + 1].current?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (!e.currentTarget.value && index > 0) {
        refs[index - 1].current?.focus();
      }
    }
  };

  const { email } = useParams();
  const [countMultipler, setCountMultipler] = useState(120);
  const location = useLocation();
  const forgotPassword = location.pathname.includes('forgot-password');
  const navigate = useNavigate();

  const { counter, setCounter } = useCounter();

  const stringOtp = otp.join('');
  // const setUserStatus = useSetRecoilState(userIsLoggedIn);

  const { mutateAsync, isLoading } = useMutationHook(verifyOtp, 'OTP');

  const _verifyOtp = async (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();

    try {
      const { data } = await mutateAsync({ otp: stringOtp, email: email! });
      if (data.status === 'success') {
        // sessionStorage.setItem('access_token', data.access_token);
        forgotPassword ? navigate(`/new-password`) : navigate(`/`);
      }
      // !forgotPassword && sessionStorage.setItem('is_login', 'true');
    } catch (error: any) {
      console.log(error);
    }
  };

  const reSendOtp = async (e: COMMONTYPES['spanClick']) => {
    e.preventDefault();
    if (!email) return;
    try {
      const { data } = await requestOtp({ email });
      console.log(data);
      // notification
      apiResponse(data, 'success', 'OTP');
      setCounter(countMultipler);
      setCountMultipler(countMultipler => countMultipler + 60);
    } catch (error: any) {
      console.log(error);
      apiResponse(error, 'error', 'OTP');
    }
  };

  useEffect(() => {
    setCounter(60);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabled = otp.some(digit => digit === '');

  return (
    <AuthContainer>
      <h1>Enter OTP</h1>
      <p className="option">Please Enter the OTP sent to {email?.toLocaleLowerCase()} </p>
      <Spacer height="2rem" />
      <form className="otp-form">
        <OtpContainer>
          {otp.map((digit, index) => (
            <React.Fragment key={index}>
              <input
                name="otp"
                key={index}
                type="text"
                value={digit}
                maxLength={1}
                onChange={e => handleChange(index, e)}
                onKeyDown={e => handleKeyDown(index, e)}
                ref={refs[index]}
              />
              {index === 2 && <Line style={{ width: '4rem' }} />}
            </React.Fragment>
          ))}
        </OtpContainer>
        <div className="option">
          {counter > 0 ? (
            <div className="resend">Resend in {counter}s </div>
          ) : (
            <span onClick={reSendOtp}>Resend OTP</span>
          )}
        </div>
        <Spacer height="2rem" />
        <div className="buttonContainer">
          <Button
            // extraStyles={bottonStyle}
            isLoading={isLoading}
            type="submit"
            onClick={_verifyOtp}
            disabled={disabled}>
            Verify OTP
          </Button>
        </div>
      </form>
    </AuthContainer>
  );
};

