import {
  DashboardIcon,
  ProductIcon,
  DeliveryIcon,
  AdminSettingsIcon,
  AdminUsersIcon,
  AdminCustomerSupportIcon,
  LoanIcon,
  OrderIcon,
  NoteFavoriteIcon,
  MadinaLogoShapeIcon,
} from '../../assets/Icons';
import { navLinkProps } from '../../components/atoms/NavLinks/NavLinks';

export const adminNavs: navLinkProps[] = [
  {
    label: 'Dashboard',
    to: '/admin/dashboard',
    Icon: DashboardIcon
  },

  {
    label: 'Products',
    to: '/admin/products',
    Icon: ProductIcon
  },

  {
    label: 'Orders',
    to: '/admin/orders',
    Icon: OrderIcon
  },

  {
    label: 'Deliveries',
    to: '/admin/deliveries',
    Icon: DeliveryIcon
  },

  {
    label: 'Users',
    to: '/admin/users',
    Icon: AdminUsersIcon
  },

  {
    label: 'BNPL',
    to: '/admin/loans',
    Icon: LoanIcon
  },

  {
    label: 'Roles',
    to: '/admin/roles',
    Icon: AdminUsersIcon
  },

  // {
  //   label: 'Loans',
  //   to: '/admin/loans',
  //   Icon: AdminUsersIcon
  // },

  {
    label: 'Categories',
    to: '/admin/categories',
    Icon: ProductIcon
  },

  // {
  //   label: 'BNPL',
  //   to: '/admin/loans',
  //   Icon: LoanIcon
  // },

  {
    label: 'Logistics',
    to: '/admin/logistics',
    Icon: LoanIcon
  },

  {
    label: 'Subscriptions',
    to: '/admin/subscriptions',
    Icon: NoteFavoriteIcon
  },
  
  {
    label: 'Shopping Assistant',
    to: '/admin/shopping-assistant',
    Icon: MadinaLogoShapeIcon
  },

  // {
  //   label: 'Fufillment',
  //   to: '/admin/fulfilments',
  //   Icon: fufillmentIcon
  // },

  // {
  //   label: 'Fufillment',
  //   to: '/admin/fulfilments',
  //   Icon: fufillmentIcon
  // },

  {
    label: 'Customer Support',
    to: '/admin/customer-support',
    Icon: AdminCustomerSupportIcon
  },

  // {
  //   label: 'Settings',
  //   to: 'admin/settings',
  //   Icon: AdminSettingsIcon
  // }
];
