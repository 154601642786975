// import { UserAction } from '../../actions';
import { formatDate } from '../../utils/dateFormater';
import { VerifySellerAction } from '../../actions/Admin/VerifyUser/verifySeller';
import { VerifyBusinessAction } from '../../actions/Admin/VerifyUser/verifyBusiness';
import { UnverifiedSellerActions } from '../../actions/Admin/VerifyUser/unverifySeller';
// import { ShopperAction } from '../../actions/User/shopperAction';
import { DeleteBusinessAction } from '../../actions/Admin/VerifyUser/deleteBusinessUser';

export const filter = [
  {
    label: 'Shoppers',
    value: 'Shoppers'
  },
  {
    label: 'Sellers',
    value: 'Sellers'
  },

  {
    label: 'Businesses',
    value: 'Businesses'
  },
  {
    label: 'Admins',
    value: 'Admins'
  }
];

export const verifiedSellerColumn = [
  {
    id: 1,
    field: 'business_name',
    headerName: 'Name',
    flex: 2,
    minWidth: 200
  },

  {
    id: 2,
    field: 'phoneNumber',
    headerName: 'Phone Number',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'email',
    headerName: 'Email Address',
    flex: 2,
    minWidth: 200
  },

  {
    id: 4,
    field: 'business_address',
    headerName: 'Business Address',
    flex: 2,
    minWidth: 200
  },

  {
    id: 5,
    field: 'location',
    headerName: 'Location',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => formatDate(row?.location?.name)
  },

];

export const shopperColumn = [
  {
    id: 1,
    field: 'name',
    headerName: "Customer's Name",
    flex: 2,
    minWidth: 150
  },

  {
    id: 3,
    field: 'email',
    headerName: 'Email Address',
    flex: 2,
    minWidth: 200
  },

  {
    id: 4,
    field: 'dateJoined',
    headerName: 'Date Joined',
    flex: 1,
    renderCell: ({ row }: any) => formatDate(row.dateJoined)
  }

  // {
  //   id: 8,
  //   field: 'action',
  //   headerName: 'Action',
  //   flex: 0,
  //   minWidth: 100,
  //   renderCell: ({ row }: any) => <ShopperAction rowid={row.id} />
  // }
];

export const unverifiedSellerColumn = [
  {
    id: 1,
    field: 'business_name',
    headerName: 'Name',
    flex: 1,
    minWidth: 150
  },

  {
    id: 2,
    field: 'phoneNumber',
    headerName: 'Phone Number',
    flex: 1,
    minWidth: 100
  },

  {
    id: 3,
    field: 'email',
    headerName: 'Email Address',
    flex: 1,
    minWidth: 150
  },

  {
    id: 4,
    field: 'business_address',
    headerName: 'Business Address',
    flex: 1,
    minWidth: 150
  },

  {
    id: 4,
    field: 'location',
    headerName: 'Location',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => formatDate(row?.location?.name)
  },

  {
    id: 6,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <VerifySellerAction id={row.vendorId} />
  }
];

export const verifiedBusinessColumn = [
  {
    id: 1,
    field: 'businessName',
    headerName: 'Business Name',
    flex: 1,
    minWidth: 150
  },

  {
    id: 3,
    field: 'email',
    headerName: 'Email Address',
    flex: 1,
    minWidth: 100
  },

  // {
  //   id: 4,
  //   field: 'cacReg',
  //   headerName: 'CAC Registration Number',
  //   flex: 1,
  //   minWidth: 100
  // },

  {
    id: 4,
    field: 'dateJoined',
    headerName: 'Date Joined',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => formatDate(row.dateJoined)
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => <DeleteBusinessAction rowid={row.id} />
  }
];

export const unverifiedBusinessColumn = [
  {
    id: 1,
    field: 'businessName',
    headerName: 'Business Name',
    flex: 1,
    minWidth: 150
  },

  {
    id: 2,
    field: 'email',
    headerName: 'Email Address',
    flex: 2,
    minWidth: 300
  },

  {
    id: 3,
    field: 'cac',
    headerName: 'CAC Registration Number',
    flex: 1,
    minWidth: 200
  },

  {
    id: 4,
    field: 'dateJoined',
    headerName: 'Date Joined',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => formatDate(row.dateJoined)
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <VerifyBusinessAction id={row.id} />
  }
];
