import { COLORS } from "../theme/color";

export const getBackgroundColor = (status: string) => {
  const lowerCaseStatus = status.toLowerCase();

  if (lowerCaseStatus.includes('pending')) {
    return '#FFF6C7';
  } else if (lowerCaseStatus.includes('completed') || lowerCaseStatus.includes('ongoing')) {
    return '#EEFBF4';
  } else if (lowerCaseStatus.includes('rejected') || lowerCaseStatus.includes('cancelled')) {
    return '#FFF0F3';
  }
  return '#FFFFFF'; // default color
};

export const getColor = (status: string) => {
  const lowerCaseStatus = status.toLowerCase();

  if (lowerCaseStatus.includes('pending')) {
    return '#F79009';
  } else if (lowerCaseStatus.includes('completed') || lowerCaseStatus.includes('ongoing')) {
    return '#27AE60';
  } else if (lowerCaseStatus.includes('rejected') || lowerCaseStatus.includes('cancelled')) {
    return COLORS.MainRed;
  }
  return '#000000'; // default color
};
