import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const WalletCardCon = styled('div')<{ type: string }>`
  min-width: 220px;
  display: ${props => (props.type ? 'block' : 'flex')};
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.type || props.type === '0' ? '#f3f5fc' : COLORS.MainBlue)};
  color: ${props => (props.type ? COLORS.MainBlue : '#ffffff')};
  width: 100%;
  height: 113px;
  border-radius: 8px;
  padding: 9%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  .card-header {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
  }
  .amount {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
  svg {
    fill: ${props => !props.type && '#ffffff'};
    margin-right: 7px;
  }
`;

export const ConvertPointModalCon = styled('div')`
  height: 80vh;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .first {
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 24px;
    }
    .info {
      display: flex;
      align-items: flex-start;
      padding: 16px 12px;
      gap: 16px;
      height: 79px;
      background: #ebeefa;
      border-radius: 8px;
      margin-bottom: 40px;
      color: ${COLORS.MainBlue};

      font-size: 14px;
      line-height: 18px;
      p:first-of-type {
        font-weight: 400;
        margin-bottom: 10px;
      }
      p:last-of-type {
        font-weight: 500;
      }
    }
    label {
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
      margin-bottom: 8px;
    }
  }
`;
