import { DashboardHeader } from '../../components/molecules';
import { columns } from './data';
import { CustomerSupportCON, CustomerSupportTableCON } from './style';
import { CustomTable, Spacer } from '../../components/atoms';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../store/keys';
import { getUsersChatWithAdmin } from '../../network/services/admin/chats';

export const CustomerSupport = () => {
  const { data } = useQuery({
    queryKey: [QUERY_KEYS],
    queryFn: getUsersChatWithAdmin
  });
  const chats = data?.map((chat) => {
    return {
      ...chat,
      id: new Date(chat.createdDate).toISOString()
    };
  })
  return (
    <CustomerSupportCON>
      <DashboardHeader title="Customer Support" />

      <Spacer height="2rem" />
      <CustomerSupportTableCON>
        <CustomTable columns={columns} data={chats || []} />
      </CustomerSupportTableCON>
    </CustomerSupportCON>
  );
};
