import React, { useRef, useState } from 'react';
import { SubscriptionInfoCard, SubscriptionInfoValueEditMode, SubscriptionInfoValueReadMode } from './style';
import { ShoppingIcon, HorizontalDotsIcon, Arrow45DegreeIcon } from '../../../assets/Icons';
import { CustomPopover } from '../../atoms';
import { ActionCON } from '../../../actions/style';
import Loading from '../../atoms/Loading';

type SubscriptionCardProps = {
  id: number;
  name: string;
  amount: number;
  handleSubscriptionCardAction?: (subscriptionId: number | null) => void;
  mode?: 'read-only' | 'edit';
};

export function SubscriptionCard({
  id,
  amount,
  name,
  handleSubscriptionCardAction,
  mode = 'edit'
}: SubscriptionCardProps) {
  const [cardHTMLElement, setCardHTMLElement] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (cardHTMLElement != null) {
      setCardHTMLElement(null);
    } else {
      setCardHTMLElement(event.currentTarget);
    }
  };

  const options = [
    {
      label: 'View package details',
      value: 'View package details',
      Icon: Arrow45DegreeIcon,
      handleAction: handleSubscriptionCardAction ?? (() => {})
    }
  ];

  if (name === 'undefined' || !name) {
    return <Loading />;
  }

  return (
    <SubscriptionInfoCard mode={mode ?? 'edit'}>
      <div className="subscription-info-header">
        <div className="subscription-info-header-title">
          <ShoppingIcon />
          <p>{name}</p>
        </div>
        {mode === 'edit' && (
          <ActionCON aria-describedby="subscription-info-item-actions" onClick={event => handleOpen(event)}>
            <HorizontalDotsIcon />
            <CustomPopover id="role-actions" item={id} open={cardHTMLElement} options={options} />
          </ActionCON>
        )}
      </div>
      {mode === 'edit' ? (
        <SubscriptionInfoValueEditMode>{amount}</SubscriptionInfoValueEditMode>
      ) : (
        <SubscriptionInfoValueReadMode>
          Subscribe - N {amount} /{name}
        </SubscriptionInfoValueReadMode>
      )}
    </SubscriptionInfoCard>
  );
}
