import { useState, useRef, useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import SockJS from 'sockjs-client';
import { over, Client } from 'stompjs';
import { useQuery } from 'react-query';
import { getAdminPreviousChatMessages } from '../network/services/admin/chats';
import { useNavigate } from 'react-router-dom';

export interface ChatMessage {
  chatId?: string;
  content: string;
  id?: number;
  orderRef?: string;
  recipientId: string;
  senderId: string;
  status?: string;
  timestamp: string;
}
const user = {
  nickname: process.env.REACT_APP_ADMIN_CHAT_NICKNAME as string,
  fullname: process.env.REACT_APP_ADMIN_CHAT_FULLNAME as string,
  userType: 'ADMIN'
};

const useWebSocketChat = (userId: string, orderRef: string, onMessageReceivedCallback?: (message: any) => void) => {
  const navigate = useNavigate();
  const [connected, setConnected] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const stompClient = useRef<Client | null>(null);
  const queryKey = [
    'admin_chat_previous_messages',
    { nickname: process.env.REACT_APP_ADMIN_CHAT_NICKNAME, userId: userId }
  ];

  // Disable the query from automatically running by setting 'enabled' to false
  const { refetch } = useQuery({
    queryKey: queryKey,
    queryFn: getAdminPreviousChatMessages,
    enabled: !!userId, // The query will only run when userId is truthy (not undefined or empty)
    onSuccess: data => {
      setMessages(data?.filter((message: ChatMessage) => message.orderRef === `#${orderRef}`));
    }
  });

  const onMessageReceived = useCallback(
    (payload: any) => {
      const message: ChatMessage = JSON.parse(payload.body);
      setMessages(prevMessages => {
        if (message.content && message.senderId !== user.nickname) {
          return [...prevMessages, { ...message, timestamp: new Date().toISOString() }];
        }
        return [...prevMessages];
      });
      if (onMessageReceivedCallback) {
        onMessageReceivedCallback(message);
      }
    },
    [onMessageReceivedCallback]
  );

  const handleConnect = useCallback(() => {
    setConnected(true);
    if (stompClient.current) {
      stompClient.current.subscribe(`/user/${user.nickname}/queue/order-messages`, onMessageReceived);
      stompClient.current.subscribe(`/user/public`, onMessageReceived);

      stompClient.current.send(
        '/app/user.addUser',
        {},
        JSON.stringify({ ...user, status: 'ONLINE', userType: 'ADMIN' })
      );
    }
  }, [onMessageReceived]);

  const onError = useCallback((error: any) => {
    enqueueSnackbar('Something went wrong. Please refresh this page to try again!', {
      variant: 'error'
    });
  }, []);

  const markChatAsClosed = useCallback(() => {
    if (stompClient.current) {
      stompClient.current.send(`/app/mark-process/#${orderRef}/CLOSED`, {});
      stompClient.current.send(`/app/user.disconnectUser/${user.nickname}`, {}, JSON.stringify({ ...user }));
      stompClient.current.disconnect(() => {
        setConnected(false);
      });
      enqueueSnackbar('Chat has been marked as closed', {
        variant: 'info'
      });
      navigate(-1);
    }
  }, [orderRef, navigate]);

  const connect = useCallback(() => {
    if (user.nickname && user.fullname && !stompClient.current && userId) {
      const socket = new SockJS(process.env.REACT_APP_BASE_WEBSOCKET_URL as string);
      stompClient.current = over(socket) as Client;
      stompClient.current.connect({}, handleConnect, onError);
    }
    enqueueSnackbar('You have been connected to the chat', {
      variant: 'success'
    });
    refetch();
  }, [handleConnect, onError, refetch, userId]);

  const sendMessage = (chatMessage: ChatMessage, orderRef: string) => {
    if (chatMessage.content && stompClient.current) {
      stompClient.current.send(`/app/order-chat/${orderRef}`, {}, JSON.stringify(chatMessage));
      setMessages(prevMessages => {
        return [...prevMessages, chatMessage];
      });
    }
  };

  const disconnect = () => {
    if (stompClient.current) {
      enqueueSnackbar('You have been disconnected from the chat', {
        variant: 'info'
      });
      stompClient.current.send(
        `/app/user.disconnectUser/${user.nickname}`,
        {},
        JSON.stringify({ ...user, status: 'OFFLINE' })
      );
      stompClient.current.disconnect(() => {
        setConnected(false);
      });
    }
  };

  return { connected, messages, connect, sendMessage, disconnect, markChatAsClosed };
};

export default useWebSocketChat;
