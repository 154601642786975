import { styled } from '@mui/material';
import { COLORS } from '../../theme/color';

export const UserDetailsCON = styled('div')`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

export const UserDetailsContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .value {
    color: #a18d8e;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const ComplaintBox = styled('div')`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  div {
    border-radius: 12px;
    margin: 30px 0;
    background: #f9f9f9;
    padding: 30px 10px;
    width: 100%;
    p {
      color: #000;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
  }
  .label {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
`;

export const StatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) =>
    status?.toLocaleLowerCase().includes('out for delivery')
      ? '#EFF1FB'
      : status?.toLocaleLowerCase().includes('pending')
      ? '#FFF6C7'
      : '#EEFBF4'};
  color: ${({ status }) =>
    status?.toLocaleLowerCase().includes('out for delivery')
      ? COLORS.MainBlue
      : status?.toLocaleLowerCase().includes('pending')
      ? '#F79009'
      : '#27AE60'};
  display: flex;
  padding: 4px 16px;
  font-weight: 500;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;



