import { ChangeEvent, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { HidePassword, ShowPassword, UploadIcon } from '../../../assets/Icons';
import { Button, Container, Spacer } from '../../../components/atoms';
import { InputField } from '../../../components/atoms/InputField/InputField';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { register } from '../../../network/services/auth';
import { validators } from '../../../utils/validator';
import {
  AltSignUp,
  AuthContainer,
  CustomSelect,
  extraStylesInput,
  extraStylesInputPhone,
  InputCon,
  TogglePassword,
  UploadImageCon
} from '../styles';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { getAllcategories } from '../../../network/services/goods';
import { useQuery } from 'react-query';
import { MenuItem, SelectChangeEvent } from '@mui/material';

const INITIALSTATE = {
  email: '',
  password: '',
  phoneNumber: '',
  government_id: '',
  business_name: '',
  business_address: '',
  cac: '',
  productCategoryId: 1
};

export const SignUp = () => {
  const { data: categoriesData } = useQuery('categories', getAllcategories);
  const categories = categoriesData || [];

  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);
  const [agreement, setAgreement] = useState(false);
  const governmentIdRef = useRef<HTMLInputElement | null>(null);
  const [governmentId, setGovernmentId] = useState<string | undefined>('');
  const utitlityBiilRef = useRef<HTMLInputElement | null>(null);
  const [utilityBill, setUtilityBill] = useState<string | undefined>('');
  const onToggleAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
  };

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const { mutateAsync, isLoading } = useMutationHook(register, 'SIGNUP');

  const _register = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    console.log(formData);
    try {
      const { data } = await mutateAsync(formData);
      console.log(data);
      navigate(`/otp/${formData.email}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileRead = async (e: COMMONTYPES['inputChange']) => {
    const file = e.target.files?.[0];
    const { name } = e.target;
    if (name === 'utility_bill') {
      setUtilityBill(file?.name);
    }
    if (name === 'government_id') {
      setGovernmentId(file?.name);
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        setFormData(formData => ({ ...formData, [name]: base64String }));
      };
      reader.readAsDataURL(file);
    }
  };

  const disabled =
    !formData.email ||
    !formData.password ||
    !formData.phoneNumber ||
    !!validators.email(formData.email) ||
    !!validators.password(formData.password);

  return (
    <AuthContainer>
      <div>
        <h1>Create an account</h1>
        <p className="option">
          Already have an account? <span onClick={() => navigate('/')}>Log in</span>
        </p>
        <Spacer height="2rem" />
        <Container>
          <form className="sign-up">
            <AltSignUp>
              <InputCon>
                <span>Business Name</span>
                <InputField
                  name="business_name"
                  onChange={onUpdateFormData}
                  placeholder="Enter a business name"
                  extraStyles={extraStylesInput}
                  required={true}
                  error={formError.business_name}
                />
              </InputCon>
              <Spacer height="2rem" width="2rem" />
              <InputCon>
                <span>Business Address</span>
                <InputField
                  name="business_address"
                  onChange={onUpdateFormData}
                  placeholder="Enter a business address"
                  extraStyles={extraStylesInput}
                  required={true}
                  error={formError.business_address}
                />
              </InputCon>
            </AltSignUp>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Email Address</span>
              <InputField
                type="email"
                name="email"
                onChange={onUpdateFormData}
                placeholder="example@mail.com"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.email}
                autoComplete="username"
              />
            </InputCon>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Phone Number</span>
              <PhoneInput
                country={'ng'}
                value={formData.phoneNumber}
                onChange={phone => {
                  setFormData({ ...formData, phoneNumber: phone });
                }}
                placeholder={'80XXXXXXXX'}
                autoFormat={false}
                inputStyle={extraStylesInputPhone}
                // error={formError.phoneNumber}
              />
            </InputCon>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>CAC Reg Number</span>
              <InputField
                type="text"
                name="cac"
                onChange={onUpdateFormData}
                placeholder="YUGJHGH877"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.cac}
              />
            </InputCon>
            <Spacer height="1.5rem" />

            <InputCon>
              <span>Category </span>
              <CustomSelect
                name="productCategoryId"
                value={formData.productCategoryId}
                onChange={onSelectChange}
                onClick={selectClick}>
                {categories.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </InputCon>
            <Spacer height="1.5rem" />

            <InputCon>
              <span>Password</span>
              <InputField
                type={showPassword}
                name="password"
                onChange={onUpdateFormData}
                placeholder="enter your password"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.password}
                action={
                  <TogglePassword onClick={onTogglePassword}>
                    {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                  </TogglePassword>
                }
                autoComplete="new-passowrd"
              />
            </InputCon>

            <Spacer height="1.5rem" />

            <UploadImageCon>
              <span>Utility Bill</span>
              <div onClick={() => utitlityBiilRef.current?.click()}>
                <p>{utilityBill || 'Upload Document'}</p>
                <UploadIcon />
                <input
                  type="file"
                  ref={utitlityBiilRef}
                  name="utility_bill"
                  onChange={handleFileRead}
                  required={true}
                />
              </div>
            </UploadImageCon>

            <Spacer height="1.5rem" />

            <Spacer height="1.5rem" />

            <UploadImageCon>
              <span>Government Issued ID</span>
              <div onClick={() => governmentIdRef.current?.click()}>
                <p>{governmentId || 'Upload Document'}</p>
                <UploadIcon />
                <input
                  type="file"
                  ref={governmentIdRef}
                  name="government_id"
                  onChange={handleFileRead}
                  required={true}
                />
              </div>
            </UploadImageCon>

            <Spacer height="1.5rem" />

            <div className="policy">
              <input type="checkbox" name="policy" id="policy" onChange={onToggleAgreement} />
              <p className="option">
                I have read, understood and I agree to Madina’s<span> Privacy Policy</span>, and{' '}
                <span>Terms and conditions</span>.
              </p>
            </div>

            <div className="buttonContainer">
              <Button
                // extraStyles={bottonStyle}
                isLoading={isLoading}
                type="submit"
                onClick={_register}
                disabled={disabled || isLoading || !agreement}>
                Create Account
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </AuthContainer>
  );
};
