import { GET_ADMIN_DELIVERIES, GET_ADMIN_DELIVERY } from '../../config/endpoints';
import api from '../../config/setAuthHeader';

export const getAdminDeliveries = async () => {
  const { data } = await api.get(`${GET_ADMIN_DELIVERIES}`);
  return data;
};

export const getAdminDeliveriesAnalytics = async () => {
  const { data } = await api.get(`${GET_ADMIN_DELIVERY}`);
  return data;
};
