/* eslint-disable @typescript-eslint/no-unused-vars */
import { AAddUser, VerifyUser } from '../../../TYPES/api.body';
import {
  ADMIN_ADD_USER,
  ADMIN_GET_BUSINESS_DETAILS,
  ADMIN_VERIFY_BUSINESS,
  ADMIN_VERIFY_SELLER,
  ADMIN_DELETE_USER,
  // GET_ADMIN_OVERVIEW,
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_ANALYTICS,
  GET_BUSINESS,
  GET_SELLERS,
  GET_SHOPPERS,
  GET_ADMINS,
  ADMIN_DELETE_SHOPPER_BUSINESS,
} from '../../config/endpoints';
import api from '../../config/setAuthHeader';

export const getAllAdminUsers = async () => {
  const { data } = await api.get(`${GET_ADMIN_USERS}`);
  return data;
};

export const getAdminUsersAnalytics = async () => {
  const { data } = await api.get(`${GET_ADMIN_USERS_ANALYTICS}`);
  return data;
};

export const getShoppers = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size, } = payload;
  const { data } = await api.get(`${GET_SHOPPERS}?page=${page}&size=${size}`);
  return data;
};

export const getSellers = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size, } = payload;
  const { data } = await api.get(`${GET_SELLERS}?page=${page}&size=${size}`);
  return data;
};

export const getBusinesses = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size, } = payload;
  const { data } = await api.get(`${GET_BUSINESS}?page=${page}&size=${size}`);
  return data;
};

export const getAdmins = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size, } = payload;
  const { data } = await api.get(`${GET_ADMINS}?page=${page}&size=${size}`);
  return data;
};

export const verifySeller = async ({ id, status, reason }: VerifyUser) => {
  const data = await api.put(`${ADMIN_VERIFY_SELLER}/${id}`, { status, reason });
  return data;
};

export const deleteUser = async (id: number) => {
  const data = await api.delete(`${ADMIN_DELETE_USER}/${id}`);
  return data;
};

export const deleteShopperAndBusiness = async (id: number) => {
  const data = await api.delete(`${ADMIN_DELETE_SHOPPER_BUSINESS}/${id}`);
  return data;
};

export const verifyBusiness = async ({ id, status, reason }: VerifyUser) => {
  const data = await api.put(`${ADMIN_VERIFY_BUSINESS}/${id}`, { status, reason });
  return data;
};

export const getBusinessDetails = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { id } = payload;
  const data = await api.get(`${ADMIN_GET_BUSINESS_DETAILS}/${id}`);
  return data;
};

export const adminAddNewUser = async (formData: AAddUser) => {
  return await api.post(ADMIN_ADD_USER, formData);
};
