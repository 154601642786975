import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal, TextEditor } from '../../../components/molecules';
import { useRef, useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';

// import { addNewUser, getLocation, getRoles } from '../../../network/services/users';
import { useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { QUERY_KEYS } from '../../../store/keys';
import { createShoppingAssistant, CreateShoppingAssistantType } from '../../../network/services/shoppingAssistant';
import { FormCON, InputCon, extraStylesInput } from './style';

type CreateShoppingAssistantProps = {
  onModalClose: () => void;
};

const INITIAL_STATE: CreateShoppingAssistantType = {
  modelName: '',
  instruction: '',
  tool: '',
  assistantName: ''
};

const CreateShoppingAssistant = ({ onModalClose }: CreateShoppingAssistantProps) => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const onTextEditorChange = (value: string) => {
    const strippedValue = value.replace(/<[^>]*>?/gm, '');
    setFormData(formData => ({ ...formData, instruction: strippedValue }));
  };

  const { mutateAsync, isLoading: isLoadingCreate } = useMutationHook(
    createShoppingAssistant,
    'CREATE_SHOPPING_ASSISTANT'
  );

  const _createSubscriptionPackage = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const { data } = await mutateAsync(formData);
      if (data.id) {
        queryClient.invalidateQueries(QUERY_KEYS.SHOPPING_ASSISTANTS);
        onModalClose();
      }
    } catch (error: any) {}
  };

  const disabled = !formData.modelName || !formData.instruction || !formData.tool || !formData.assistantName;
  return (
    <Modal title="Create Assistant" maxWidth="700px" onModalClose={onModalClose}>
      <FormCON>
        <InputCon>
          <span>Assistant Name</span>
          <InputField
            extraStyles={extraStylesInput}
            value={formData.assistantName}
            onChange={onInputChange}
            name="assistantName"
            placeholder="name of the AI assistant, e.g Customer Shopping Assistant"
          />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Model Name</span>
          <InputField
            extraStyles={extraStylesInput}
            value={formData.modelName}
            onChange={onInputChange}
            placeholder="e.g. gpt-3.5-turbo-1106"
            name="modelName"
          />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Instructions</span>
          <TextEditor inputRef={inputRef} onChange={onTextEditorChange} />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Tools</span>
          <InputField
            extraStyles={extraStylesInput}
            value={formData.tool}
            onChange={onInputChange}
            placeholder="Supported values are: retrieval, function, code_interpreter"
            name="tool"
          />
        </InputCon>

        <Spacer height="52px" />

        <Button onClick={_createSubscriptionPackage} isLoading={isLoadingCreate} disabled={disabled}>
          Create
        </Button>
      </FormCON>
    </Modal>
  );
};

export default CreateShoppingAssistant;
