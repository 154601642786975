import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { AdduserCon, InputCon, extraStylesInput, CustomSelect } from './style';
import { useRef, useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { useQuery, useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { UploadImageCon } from '../style';
import { UploadIcon } from '../../../assets/Icons';
import { addCategory, addSubCategory } from '../../../network/services/admin/categories';
import { getAllcategories } from '../../../network/services/goods';
import { MenuItem, SelectChangeEvent } from '@mui/material';

type AddSubCategoryProps = {
  onModalClose: () => void;
};

const INITIAL_STATE = {
  name: '',
  categoryId: 0
};

export const AddSubCategory = ({ onModalClose }: AddSubCategoryProps) => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const logoRef = useRef<HTMLInputElement | null>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const queryClient = useQueryClient();

  const { data: categoriesData } = useQuery('categories', getAllcategories);
  const categories = categoriesData || [];

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const { mutateAsync, isLoading } = useMutationHook(addSubCategory, 'ADD_SUB_CATEGORY');

  const _addCategory = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const payload = new FormData();
    payload.append(
      'request',
      new Blob([JSON.stringify({ name: formData.name, categoryId: formData.categoryId })], { type: 'application/json' })
    );
    payload.append('sub_category_logo', logo as File);

    try {
      const data = await mutateAsync(payload);
      if ((data as any).status === 'success') {
        queryClient.invalidateQueries('admin_categories');
        onModalClose();
      }
    } catch (error: any) {}
  };

  const handleFileRead = (e: COMMONTYPES['inputChange']) => {
    const files = e.target.files;
    files && setLogo(files[0]);
  };

  const disabled = !formData.name || !logo?.name.length || isLoading;
  return (
    <Modal title="Add Sub Category" maxWidth="500px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Category </span>
          <CustomSelect name="categoryId" value={formData.categoryId} onChange={onSelectChange} onClick={selectClick}>
            {categories.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Sub-category Name</span>
          <InputField extraStyles={extraStylesInput} onChange={onInputChange} name="name" />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <UploadImageCon>
            <span>Sub-category Logo</span>
            <div onClick={() => logoRef.current?.click()}>
              <p>{logo?.name || 'Upload File'}</p>
              <UploadIcon />
              <input type="file" ref={logoRef} name="logo" onChange={handleFileRead} required={true} />
            </div>
          </UploadImageCon>
        </InputCon>

        <Spacer height="52px" />

        <Button onClick={_addCategory} isLoading={isLoading} disabled={disabled}>
          Add
        </Button>
      </AdduserCon>
    </Modal>
  );
};
