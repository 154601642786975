import { ResponseSuccess } from '../../TYPES/api.body';
import { ResponseContent } from '../../TYPES/response';
import {
  ADMIN_CREATE_SUBSCRIPTION_PACKAGE,
  ADMIN_GET_SUBSCRIBERS,
  ADMIN_GET_SUBSCRIPTIONS,
  ADMIN_GET_SUBSCRIPTION_PACKAGE_DETAILS,
  ADMIN_UPDATE_SUBSCRIPTION_PACKAGE
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export interface SubscriptionPackage {
  id?: number;
  name: string;
  amount: number;
  duration: number;
  benefits: string[];
  legibleForFreeTrial: boolean;
}

export interface SubscriptionSubcriber {
  subscriptionAmount: number;
  lastBilling: string;
  nextBilling: string;
  packageName: string;
  status: string;
  subscriber: string;
}

export interface SubscriptionResponsePaginated {
  currentPage: number;
  data: SubscriptionSubcriber[];
  message: string;
  status: string;
  totalElements: number;
  totalPages: number;
}

export const getSubscriptionPackages = async (): Promise<ResponseContent<SubscriptionPackage[]>> => {
  const { data } = await api.get(ADMIN_GET_SUBSCRIPTIONS);
  return data;
};

export const getSubscriptionSubscribers = async ({
  queryKey
}: any): Promise<ResponseContent<SubscriptionResponsePaginated>> => {
  const [_, payload] = queryKey;
  const { page, size } = payload;
  const { data } = await api.get(`${ADMIN_GET_SUBSCRIBERS}?page=${page}&pageSize=${size}`);
  return data;
};

export const getSubscriptionPackageDetails = async ({
  queryKey
}: any): Promise<ResponseContent<SubscriptionPackage>> => {
  const [_, payload] = queryKey;
  const { subscriptionId } = payload;
  const { data } = await api.get(`${ADMIN_GET_SUBSCRIPTION_PACKAGE_DETAILS}/${subscriptionId}`);
  return data;
};

export const createSubscriptionPackage = async (
  formData: Omit<SubscriptionPackage, 'id'>
): Promise<ResponseContent<ResponseSuccess>> => {
  const { data } = await api.post(ADMIN_CREATE_SUBSCRIPTION_PACKAGE, formData);
  return data;
};

export const updateSubscriptionPackage = async (
  formData: SubscriptionPackage
): Promise<ResponseContent<ResponseSuccess>> => {
  const subscriptionId = formData.id;
  const { data } = await api.put(`${ADMIN_UPDATE_SUBSCRIPTION_PACKAGE}/${subscriptionId}/edit`, formData);
  return data;
};
