import React, { useState } from 'react';
import { Modal } from '../../../components/molecules/Modal/Modal';
import { useQuery, useQueryClient } from 'react-query';
import { getBusinessDetails, verifyBusiness } from '../../../network/services/admin/users';
import { ApproveBtnExtraStyles, DeclineBtnExtraStyles, DeclineBusinessModal, ImageCon, TextEditorCON, UserDetailsCon } from './style';
import { Button, EmptyCon, Spacer } from '../../../components/atoms';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { CircularProgress } from '@mui/material';

type BusinessDetailsProps = {
  id: number;
  onModalClose: () => void;
};

export const BusinessDetails = ({ id, onModalClose }: BusinessDetailsProps) => {
  const [seeGovernmentID, setGovernmentID] = useState(false);
  const [seeUtilityBill, setUtilityBill] = useState(false);
  const [openDeclineBusinessModal, setOpenDeclineBusinessModal] = useState<boolean>(false);
  const [declineReason, setDeclineReason] = useState<string>("");

  const queryClient = useQueryClient();

  const { data, isFetchedAfterMount } = useQuery(['business_details', { id }], getBusinessDetails, {
    enabled: !!id
  });
  const details = data?.data?.data || {};

  const { mutateAsync, isLoading } = useMutationHook(verifyBusiness);

  const _verify = async (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();
    try {
      await mutateAsync({ id: id, status: 'true', reason: '' });
      queryClient.invalidateQueries('admin_business');
      onModalClose();
    } catch (error: any) {}
  };

  const _decline = async (e: COMMONTYPES['buttonClick']) => {
    e.preventDefault();
    try {
      await mutateAsync({ id: id, status: 'false', reason: declineReason });
      queryClient.invalidateQueries('admin_business');
      onModalClose();
    } catch (error: any) {}
  };

  const toggleDeclineModal = () => {
    setOpenDeclineBusinessModal(!openDeclineBusinessModal);
  }

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDeclineReason(event.target.value);
  };

  const _seeUtilityBill = () => {
    setGovernmentID(false);
    setUtilityBill(true);
  };

  const _seeGovernmentId = () => {
    setUtilityBill(false);
    setGovernmentID(true);
  };
  return (
    <div>
      <Modal title="View" maxWidth="500px" onModalClose={onModalClose}>
        {!isFetchedAfterMount && (
          <EmptyCon>
            <CircularProgress size={30} />
          </EmptyCon>
        )}
        {isFetchedAfterMount && (
          <UserDetailsCon>
            <div className="content">
              <p className="label">Business Name</p>
              <p className="value">{details?.businessName}</p>
            </div>
            <div className="content">
              <p className="label">Address</p>
              <p className="value">{details?.addresses}</p>
            </div>

            <div className="content">
              <p className="label">Email </p>
              <p className="value">{details?.email}</p>
            </div>

            <div className="content">
              <p className="label">Phone Number </p>
              <p className="value">{details?.phoneNumber}</p>
            </div>

            <div className="content">
              <p className="label">CAC Registration Number</p>
              <p className="value">{details?.cac}</p>
            </div>

            {/* <div className="content">
          <p className="label">BVN</p>
          <p className="value">{details?.bvn}</p>
        </div>

        <div className="content">
          <p className="label">NIN</p>
          <p className="value">{details?.nin}</p>
        </div> */}

            <div className="content">
              <p className="label">Valid ID</p>
              <Button onClick={_seeGovernmentId}>View Image</Button>
            </div>

            <div className="content">
              <p className="label">Utility Bill</p>
              <Button onClick={_seeUtilityBill}>View Image</Button>
            </div>

            <Spacer height="2rem" />

            <div className="action-buttons">
              <Button disabled={details?.status !== 'false'} onClick={toggleDeclineModal} isLoading={isLoading} extraStyles={DeclineBtnExtraStyles}>
                Decline
              </Button>
              <Button
                disabled={details?.status === 'true'}
                onClick={_verify}
                isLoading={isLoading}
                extraStyles={ApproveBtnExtraStyles}>
                Approve
              </Button>
            </div>
          </UserDetailsCon>
        )}
      </Modal>

      {seeUtilityBill && (
        <Modal maxWidth="400px" onModalClose={() => setUtilityBill(false)}>
          <ImageCon>
            <img src={details?.utility_bill} alt="" />
          </ImageCon>
        </Modal>
      )}

      {seeGovernmentID && (
        <Modal maxWidth="400px" onModalClose={() => setGovernmentID(false)}>
          <ImageCon>
            <img src={details?.government_id} alt="" />
          </ImageCon>
        </Modal>
      )}

      {openDeclineBusinessModal && (
        <Modal title="Decline Request" maxWidth="400px" onModalClose={() => setOpenDeclineBusinessModal(false)}>
          <DeclineBusinessModal>
          <TextEditorCON>
            <span>Reason for decline</span>
            <textarea onChange={handleTextareaChange}/>
          </TextEditorCON>
          <Button disabled={details?.status !== 'false'} onClick={_decline} isLoading={isLoading} extraStyles={DeclineBtnExtraStyles}>
            Decline
          </Button>

          </DeclineBusinessModal>
        </Modal>
      )}
    </div>
  );
};
