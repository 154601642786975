import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { useRef, useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';

// import { addNewUser, getLocation, getRoles } from '../../../network/services/users';
import { useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import {
  createShoppingAssistantFile,
  CreateShoppingAssistantFilePayload,
  linkFileToAssistant
} from '../../../network/services/shoppingAssistant';
import { FormCON, InputCon, extraStylesInput, UploadImageCon } from './style';
import { UploadIcon } from '../../../assets/Icons';

type CreateShoppingAssistantFileProps = {
  onModalClose: () => void;
};

const INITIAL_STATE: CreateShoppingAssistantFilePayload = {
  file: new File([''], 'filename'),
  purpose: ''
};

const CreateShoppingAssistantFile = ({ onModalClose }: CreateShoppingAssistantFileProps) => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const fileRef = useRef<HTMLInputElement | null>(null);

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const handleFileRead = async (e: COMMONTYPES['inputChange']) => {
    const file = e.target.files?.[0];
    setFormData(formData => ({ ...formData, file: file as File }));
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

  const { mutateAsync, isLoading: isLoadingCreate } = useMutationHook(
    createShoppingAssistantFile,
    'CREATE_SHOPPING_ASSISTANT_FILE'
  );

  const { mutateAsync: mutateAsyncLinkFileToAssistant } = useMutationHook(
    linkFileToAssistant,
    'LINK_ASSISTANT_TO_FILE'
  );

  const _createShoppingAssistantFile = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const payload = new FormData();
    payload.append('file', formData.file);
    payload.append('purpose', formData.purpose);
    try {
      const { data } = await mutateAsync(payload);
      if (data.id) {
        await mutateAsyncLinkFileToAssistant(undefined);
        onModalClose();
      }
    } catch (error: any) {}
  };

  const disabled = !formData.purpose || !formData.file;
  return (
    <Modal title="Create Assistant File" maxWidth="700px" onModalClose={onModalClose}>
      <FormCON>
        <InputCon>
          <span>Purpose</span>
          <InputField
            extraStyles={extraStylesInput}
            value={formData.purpose}
            onChange={onInputChange}
            name="purpose"
            placeholder="name of the AI assistant, e.g Customer Shopping Assistant"
          />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <UploadImageCon>
            <span>File</span>
            <div onClick={() => fileRef.current?.click()}>
              <p>Upload File</p>
              <UploadIcon />
              <input type="file" ref={fileRef} name="file" onChange={handleFileRead} required={true} />
            </div>
            {formData.file.name && <span>Filename: {formData.file.name}</span>}
          </UploadImageCon>
        </InputCon>
        <Spacer height="52px" />

        <Button onClick={_createShoppingAssistantFile} isLoading={isLoadingCreate} disabled={disabled}>
          Create
        </Button>
      </FormCON>
    </Modal>
  );
};

export default CreateShoppingAssistantFile;
