import { Button, Divider, Spacer } from '../../../components/atoms';
import { Modal, SubscriptionCard } from '../../../components/molecules';
import { SubscriptionPackageDescriptionCON, SubscriptionPackageDetailsCON } from './style';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../store/keys';
import { getSubscriptionPackageDetails, SubscriptionPackage } from '../../../network/services/subscriptions';

type ViewSubscriptionPackageProps = {
  onModalClose: () => void;
  subscriptionId: number;
  handleViewEditSubscription: (subscription: SubscriptionPackage) => void;
};
const ViewSubscriptionPackage = ({
  onModalClose,
  subscriptionId,
  handleViewEditSubscription
}: ViewSubscriptionPackageProps) => {
  const { data } = useQuery(
    [QUERY_KEYS.SUBSCRIPTION_PACKAGE_DETAILS, { subscriptionId }],
    getSubscriptionPackageDetails
  );
  const subscriptionData = data?.data;

  const onClickHandler = () => {
    handleViewEditSubscription({ ...subscriptionData, id: subscriptionId } as SubscriptionPackage);
  };

  return (
    <Modal title="Madina Subscription" maxWidth="700px" onModalClose={onModalClose}>
      <SubscriptionPackageDetailsCON>
        <SubscriptionCard
          id={subscriptionId}
          amount={Number(subscriptionData?.amount)}
          name={String(subscriptionData?.name)}
          mode="read-only"
        />
        <Spacer height="52px" />
        <h3>{subscriptionData?.name} package details</h3>
        <Divider />
        <Spacer height="10px" />
        {/* <Button onClick={() => } isLoading={isLoading} disabled={disabled}>
          Create Package
        </Button> */}
        <SubscriptionPackageDescriptionCON>
          <p>Unlock savings with our Subscription Card:</p>
          <ul>
            {subscriptionData?.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
        </SubscriptionPackageDescriptionCON>
        <Button onClick={onClickHandler}>Edit Package</Button>
      </SubscriptionPackageDetailsCON>
    </Modal>
  );
};

export default ViewSubscriptionPackage;
