import { useMediaQuery, Theme } from '@mui/material';
import { TopHeaderCON, IconCon, CardCON, CardDivider, PercentageCON } from './style';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export type cardItemProps = {
  title: string;
  total: number;
  percentage?: string;
  iconBg?: string;
  Icon?: any;
};

interface TopHeaderCardProps {
  data: cardItemProps[];
  gap?: string;
}

export function TopHeaderCard(props: TopHeaderCardProps) {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { data, gap = '' } = props;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1162 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1162, min: 900 },
      items: 2
    },
    small_tablet: {
      breakpoint: { max: 900, min: 800 },
      items: 3
    },
    large_phone: {
      breakpoint: { max: 800, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

  return (
    <TopHeaderCON gap={gap}>
      {!md &&
        data.map(({ title, total, iconBg, Icon, percentage }, index) => (
          <CardCON key={index}>
            <div>
              <p>{title}</p>
              <h3>{total}</h3>
              {!!percentage && <PercentageCON percentage={percentage}>{percentage.trim()}</PercentageCON>}
            </div>
            <IconCon color={iconBg}>
              <Icon />
            </IconCon>
            {data.length !== index + 1 && <CardDivider color="#E9E5E5" />}
          </CardCON>
        ))}

      {md && (
        <Carousel autoPlay infinite arrows={false} showDots className="carousel-con" responsive={responsive}>
          {data.map(({ title, total, iconBg, Icon, percentage }, index) => (
            <CardCON key={index}>
              <div>
                <p>{title}</p>
                <h3>{total}</h3>
                {!!percentage && <PercentageCON percentage={percentage}>{percentage.trim()}</PercentageCON>}
              </div>
              <IconCon color={iconBg}>
                <Icon />
              </IconCon>
              {data.length !== index + 1 && <CardDivider color="#E9E5E5" />}
            </CardCON>
          ))}
        </Carousel>
      )}
    </TopHeaderCON>
  );
}
