import { useRecoilState } from 'recoil';
import { Action, BrokenGclose } from '../../../assets/Icons';
import { CustomPopover } from '../../../components/atoms';
import { ActionCON } from '../../style';
import { actionPopover } from '../../../store/atoms/orderAtom';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { useQueryClient } from 'react-query';
import { deleteShopperAndBusiness } from '../../../network/services/admin/users';

export const DeleteBusinessAction = ({ rowid }: { rowid: number }) => {
  const [value, setValue] = useRecoilState(actionPopover);
  const queryClient = useQueryClient();

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const { mutateAsync } = useMutationHook(deleteShopperAndBusiness);

  const _delete = async () => {
    try {
      await mutateAsync(rowid);
      queryClient.invalidateQueries('admin_business');
    } catch (error: any) {}
  };

  const options = [
    {
      label: 'Remove User',
      value: 'Remove User',
      Icon: BrokenGclose,
      color: '#D90429',
      handleAction: _delete
    }
  ];
  return (
    <ActionCON aria-describedby="verify-business-actions" onClick={event => handleOpen(event)}>
      <Action />
      <CustomPopover id="verify-business-actions" open={value} options={options} />
    </ActionCON>
  );
};
