import { styled, Tab, Tabs } from '@mui/material';
import { COLORS } from '../../theme/color';

export const OrderCON = styled('div')``;

export const TableCON = styled('div')`
  background: ${COLORS.White};
`;

export const TableHeaderCON = styled('div')`
  padding: 32px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoanStatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) =>
    // status?.toLocaleLowerCase().includes('request')
    //   ? '#EFF1FB'
      status?.toLocaleLowerCase().includes('approved')
      ? '#EEFBF4'
      : '#FFF6C7'};
  color: ${({ status }) =>
    // status?.toLocaleLowerCase().includes('request')
    //   ? '#C4A62F'
      status?.toLocaleLowerCase().includes('approved')
      ? '#27AE60'
      : '#F79009'};
  display: flex;
  padding: 4px 16px;
  font-weight: 500;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;

export const extraButtonStyles = {
  color: `${COLORS.MainBlue}`,
  backgroundColor: '#EFF1FB',
  borderRadius: '12px',
  height: '31px',
  width: '102px',
  fontSize: '14px',
  fontWeight: '400'
};

export const CustomAuthTab = styled(Tab)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '26.483px',
  textTransform: 'capitalize',
  maxWidth: '60px',
  width: '60px',
  color: '#777777',
  fontStyle: 'normal',
  '&.Mui-selected': {
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'capitalize',
    backgroundColor: `${COLORS.White}`,
    borderRadius: '23px',
    width: '162px',
    [theme.breakpoints.down('sm')]: {
      width: '110px'
    },
    marginTop: '5px',
    height: '36px',
    minHeight: '36px'
  }
}));

export const ActionCON = styled('div')`
  cursor: pointer;
`;

export const CustomAuthTabs = styled(Tabs)(({ theme }) => ({
  padding: '0px 12px',
  height: '46px',
  gap: '10px',
  minHeight: 'max-content',
  '& .MuiTabs-indicator': {
    height: '4px'
  },
  [theme.breakpoints.down('sm')]: {}
}));
