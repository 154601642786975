import { GreenDot, OrangeDot } from '../../assets/Icons';
// import { CheckIcon, MessageIcon , GreenDot, OrangeDot } from '../../assets/Icons';
import { formatCurrency } from '../../utils/currencyFormater';
import { formatDate } from '../../utils/dateFormater';
import { DeliveryStatusCON } from './style';

export const filter = [
  {
    label: 'Delivered',
    value: 'Delivered'
  },
  {
    label: 'Delivery Request',
    value: 'Delivery Request'
  }
];

export const columns = [
  {
    id: 1,
    field: 'orderId',
    headerName: 'Order ID',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{row?.orders?.orderRef}</p>
  },

  {
    id: 2,
    field: 'name',
    headerName: "Customer's Name",
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <p>
        {row?.customerName?.firstname} {row?.customerName?.lastname}
      </p>
    )
  },

  {
    id: 3,
    field: 'numberOfItem',
    headerName: 'No. of Items ',
    minWidth: 50
  },

  {
    id: 4,
    field: 'price',
    headerName: 'Price ',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatCurrency(row?.price)} </p>
  },

  {
    id: 5,
    field: 'deliveryDate',
    headerName: 'Delivery Date',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatDate(row?.deliveryDate)} </p>
  },

  {
    id: 6,
    field: 'deliveryType',
    headerName: 'Delivery Type',
    flex: 2,
    minWidth: 200
  },

  {
    id: 7,
    field: 'logisticCompany',
    headerName: 'Logistics',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.logisticCompany?.name ?? row?.logisticCompany} </p>
  },

  {
    id: 8,
    field: 'status',
    headerName: 'status',
    flex: 2,
    minWidth: 150,
    renderCell: ({ row }: any) => (
      <DeliveryStatusCON status={row.status}>
        {row.status.includes('completed') ? <GreenDot /> : <OrangeDot />}
        <p>{row.status}</p>
      </DeliveryStatusCON>
    )
  }
];

export const deliveries = [
  {
    id: 1,
    key: 'name',
    name: 'BillsOnMe',
    orderId: '#RA902282',
    deliveryType: 'Pickup',
    noItems: '128',
    deliveryDate: '7/03/22, 08:00pm',
    price: 'N230,000',
    status: 'completed',
    logisticsCompany: 'DHL',
    flex: 1
  },

  {
    id: 2,
    key: 'name',
    name: 'BillsOnMe',
    orderId: '#RA902282',
    deliveryType: 'Pickup',
    noItems: '128',
    deliveryDate: '7/03/22, 08:00pm',
    price: 'N230,000',
    status: 'intransit',
    logisticsCompany: 'DHL',
    flex: 1
  },
  {
    id: 3,
    key: 'name',
    name: 'BillsOnMe',
    orderId: '#RA902282',
    deliveryType: 'Pickup',
    noItems: '128',
    deliveryDate: '7/03/22, 08:00pm',
    price: 'N230,000',
    status: 'completed',
    logisticsCompany: 'DHL',
    flex: 1
  },
  {
    id: 4,
    key: 'name',
    name: 'BillsOnMe',
    orderId: '#RA902282',
    deliveryType: 'Pickup',
    noItems: '128',
    deliveryDate: '7/03/22, 08:00pm',
    price: 'N230,000',
    status: 'intransit',
    logisticsCompany: 'DHL',
    flex: 1
  }
];
