import { v4 as uuidv4 } from 'uuid';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, CustomTable, SelectField, Spacer } from '../../components/atoms';
import { BrokenAdd } from '../../assets/Icons';
import { DashboardHeader, SubscriptionCard } from '../../components/molecules';
import { SubscriptionTableCON, TableHeaderCON, TopHeaderSubscriptionCON } from './style';
import { useRecoilState } from 'recoil';
import { subscriptionsFilter } from '../../store/atoms/subscriptionAtom';
import { QUERY_KEYS } from '../../store/keys';
import AdminCreateUpdateSubscription from './CreateUpdateSubscription';
import {
  getSubscriptionPackages,
  getSubscriptionSubscribers,
  SubscriptionPackage,
  SubscriptionResponsePaginated
} from '../../network/services/subscriptions';
import { useQuery } from 'react-query';
import ViewSubscriptionPackage from './ViewSubscriptionPackage';
import { columns } from './data';

function Subscriptions() {
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const [isAddNewPackage, setIsAddNewPackage] = useState<boolean>(false);
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState<number | null>(null);
  const [currentSubscription, setCurrentSubscription] = useState<SubscriptionPackage | null>(null);
  const [isViewPackageDetails, setIsViewPackageDetails] = useState<boolean>(false);
  const [subscriptionFilterState, setSubscriptionFilterState] = useRecoilState(subscriptionsFilter);
  const { data: subcriptionsData } = useQuery(QUERY_KEYS.SUBSCRIPTION_PACKAGES, getSubscriptionPackages);
  const { data: subscribersData, isFetchedAfterMount: isLoadingSubscribersFinished } = useQuery(
    [QUERY_KEYS.SUBSCRIPTION_SUBSCRIBERS, { page: page - 1, size: pageSize }],
    getSubscriptionSubscribers
  );

  const castedSubscribersData = subscribersData as unknown as SubscriptionResponsePaginated;

  const subscriptions = useMemo(() => subcriptionsData?.data || [], [subcriptionsData]);
  const subscribers = useMemo(() => castedSubscribersData?.data || [], [castedSubscribersData]);

  console.log('subscribersData', subscribersData);

  const subscribersByPackage = useCallback(
    (packageName: string) => {
      return subscribers
        .filter(subscriber => subscriber.packageName === packageName)
        .map(subscriber => ({
          ...subscriber,
          id: uuidv4()
        }));
    },
    [subscribers]
  );

  useEffect(() => {
    if (subscriptions.length) {
      setSubscriptionFilterState(subscriptions[0].name);
    }
  }, [setSubscriptionFilterState, subscriptions]);

  const subscriptionNames = subscriptions.map(subscription => subscription.name);

  const subscriptionFilterOpts = subscriptionNames.map(name => ({ label: name, value: name }));

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue !== subscriptionFilterState) {
      setSubscriptionFilterState(newValue);
    }
  };

  const onAddNewPackageModalClose = () => {
    setIsAddNewPackage(false);
  };

  const onAddNewPackageModalOpen = () => {
    setIsAddNewPackage(true);
  };

  const onPackageDetailsModalClose = () => {
    setIsViewPackageDetails(false);
  };

  const onPackageDetailsModalOpen = () => {
    setIsViewPackageDetails(true);
  };

  const handleSubscriptionCardAction = (subscriptionId: number | null = null) => {
    onPackageDetailsModalOpen();
    setCurrentSubscriptionId(subscriptionId);
  };

  const handleViewEditSubscription = (subscription: SubscriptionPackage) => {
    setCurrentSubscription(subscription);
    setIsAddNewPackage(true);
    setIsViewPackageDetails(false);
  };

  return (
    <Container>
      <DashboardHeader
        title="Subscription Packages"
        buttonPlaceholder="Add New Package"
        onClick={onAddNewPackageModalOpen}
        Icon={BrokenAdd}
      />
      <TopHeaderSubscriptionCON>
        {subscriptions.map(subscription => (
          <SubscriptionCard
            id={Number(subscription?.id)}
            key={subscription.name}
            amount={subscription.amount}
            name={subscription.name}
            handleSubscriptionCardAction={handleSubscriptionCardAction}
          />
        ))}
      </TopHeaderSubscriptionCON>
      <Spacer height="48px" />

      <SubscriptionTableCON>
        <TableHeaderCON>
          <h3>{subscriptionFilterState}</h3>
          <SelectField
            handleChange={handleFilterChange}
            options={subscriptionFilterOpts}
            value={subscriptionFilterState}
          />
        </TableHeaderCON>
        {subscriptionFilterState.length > 2 && (
          <CustomTable
            isLoading={isLoadingSubscribersFinished}
            columns={columns}
            data={subscribersByPackage(subscriptionFilterState)}
            totalItems={castedSubscribersData?.totalElements ?? 0}
            totalPages={castedSubscribersData?.totalPages ?? 0}
            currentPage={page}
            setCurrentPage={setPage}
          />
        )}
      </SubscriptionTableCON>
      {isAddNewPackage && (
        <AdminCreateUpdateSubscription
          currentSubscription={currentSubscription}
          onModalClose={onAddNewPackageModalClose}
          mode={currentSubscription ? 'update' : 'create'}
        />
      )}
      {isViewPackageDetails && (
        <ViewSubscriptionPackage
          subscriptionId={Number(currentSubscriptionId)}
          onModalClose={onPackageDetailsModalClose}
          handleViewEditSubscription={handleViewEditSubscription}
        />
      )}
    </Container>
  );
}

export default Subscriptions;
