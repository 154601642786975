import { enqueueSnackbar } from 'notistack';

const MESSAGE = {
  LOGIN: {
    success: 'Login successful',
    error: 'Failed, Try Again.'
  },
  LOGOUT: {
    success: 'Logout successful',
    error: ''
  },
  SIGNUP: {
    success: 'Seller Account created',
    error: 'Failed, Try Again.'
  },
  OTP: {
    success: 'Email is verified',
    error: 'OTP Not Found or Expired'
  },
  UPDATE_PROFILE: {
    success: 'Profile updated',
    error: 'Failed, Try Again.'
  },
  CHANGE_PASSWORD: {
    success: 'Password changed successfully',
    error: 'Failed, Try Again.'
  },
  NEW_ADDRESS: {
    success: 'Address added',
    error: 'Failed, Try Again.'
  },
  UPDATE_ADDRESS: {
    success: 'Address updated',
    error: 'Failed, Try Again.'
  },
  PRODUCT_UPLOAD: {
    success: 'Product Uploaded Successfully',
    error: 'Product Upload Failed'
  },
  UPDATE_PRODUCT: {
    success: 'Product Updated Successfully',
    error: 'Product Update Failed'
  },
  ADD_NEW_USER: {
    success: 'User added successfully',
    error: 'Failed, Try Again.'
  },
  ADD_CATEGORY: {
    success: 'Category Added',
    error: 'Failed, Try Again.'
  },
  ADD_SUB_CATEGORY: {
    success: 'Sub Category Added',
    error: 'Failed, Try Again.'
  },
  UPDATE_CATEGORY: {
    success: 'Category Updated Successfully',
    error: 'Category Update Failed'
  },
  ADD_ROLE: {
    success: 'Role Added',
    error: 'Failed, Try Again.'
  },
  UPDATE_ROLE: {
    success: 'Role Updated',
    error: 'Failed, Try Again.'
  },
  ADD_LOGISTICS: {
    success: 'Logistics Added',
    error: 'Failed, Try Again.'
  },
  UPDATE_LOGISTICS: {
    success: 'Logistics Updated',
    error: 'Failed, Try Again.'
  },
  DELETE_LOGISTICS: {
    success: 'Logistics Deleted',
    error: 'Failed, Try Again.'
  },
  VERIFY_SELLER: {
    success: 'Seller verified successfully',
    error: 'Unable to verify seller'
  },
  ROLE_CHANGED: {
    success: 'Role Changed',
    error: 'Failed, Try Again.'
  },
  CANCEL_ORDER: {
    success: 'Order Canceled',
    error: 'Failed, Try Again.'
  },
  UPDATE_LOAN_SETTING: {
    success: 'Loan Setting Updated',
    error: 'Failed, Try Again.'
  },
  DELETE_LOAN_SETTING: {
    success: 'Loan Setting Deleted',
    error: 'Failed, Try Again.'
  },
  CREATE_LOAN_INTEREST: {
    success: 'Loan Interest Created',
    error: 'Failed, Try Again.'
  },
  UPDATE_LOAN_INTEREST: {
    success: 'Loan Interest Updated',
    error: 'Failed, Try Again.'
  },
  CREATE_SUBSCRIPTION_PACKAGE: {
    success: 'Subscription Package Created',
    error: 'Failed, Try Again.'
  },
  UPDATE_SUBSCRIPTION_PACKAGE: {
    success: 'Subscription Package Updated',
    error: 'Failed, Try Again.'
  },
  CREATE_SHOPPING_ASSISTANT: {
    success: 'Shopping Assistant Created',
    error: 'Failed, Try Again.'
  },
  CREATE_SHOPPING_ASSISTANT_FILE: {
    success: 'Shopping Assistant File Created',
    error: 'Failed, Try Again.'
  },
  LINK_ASSISTANT_TO_FILE: {
    success: 'Assistant linked to file',
    error: 'Failed, Try Again.'
  },
};

export type MessageProp = keyof typeof MESSAGE;

export const apiResponse = (data: Record<string, any> | null, type: 'success' | 'error' , operation?: MessageProp) => {
  const result = {
    success: data?.data?.description || data?.description,
    error: data?.response?.data?.description || data?.response?.data?.data?.description
  };

  const message = result[type] || (operation && MESSAGE[operation][type]);

  if (!message) return;

  enqueueSnackbar(message, {
    variant: data?.data?.status === 'failed' ? 'error' : type,
    autoHideDuration: type === 'error' ? 2500 : 2000
  });
};
