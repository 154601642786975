import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { TrackStatusCon } from './style';
// import { TrackStatusCon, StyledTextArea } from './style';
import { Button, CustomSelect, Spacer } from '../../../components/atoms';
import { useState } from 'react';
// import { ChangeEvent, useState } from 'react';
// import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { adminTrackStatus } from '../../../network/services/admin/order';
// import { adminCancelOrder, adminTrackStatus } from '../../../network/services/admin/order';
import { useQueryClient } from 'react-query';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { apiResponse } from '../../../network/config/message.constants';
// import { type } from 'os';

type DeclineOrderProps = {
  id: number;
  status: string;
  onModalClose: () => void;
};

export const TrackStatus = ({ id, onModalClose, status }: DeclineOrderProps) => {
  const [formData, setFormData] = useState({ status });
  const [isLoading, setisLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();

  // const onUpdateFormData = (e: ChangeEvent<HTMLTextAreaElement>) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setFormData(formData => ({ ...formData, [name]: value }));
  // };

  const _trackStatus = async () => {
    try {
      setisLoading(true);
      const data = await adminTrackStatus(id, formData.status);
      if (data?.status === 'success') {
        setisLoading(false);
        queryClient.invalidateQueries('admin_orders');
        apiResponse(data, 'success');
        onModalClose();
      }
      if (data?.status === 'failed') {
        setisLoading(false);
      }
    } catch (e: any) {
      setisLoading(false);
      apiResponse(e, 'error');
    }
  };

  console.log(status, 'hello');
  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const disabled = !formData.status;

  const statuses = [
    {
      label: 'Pending',
      value: 'pending'
    },

    {
      label: 'Approved',
      value: 'approved'
    },

    {
      label: 'Order Processed',
      value: 'order_processed'
    },

    {
      label: 'Order Out',
      value: 'order_out'
    },

    {
      label: 'Delivered',
      value: 'delivered'
    }
  ];

  return (
    <TrackStatusCon>
      <Spacer height="40px" />
      <Box>
        <CustomSelect
          name="status"
          placeholder="Select Status"
          value={formData.status}
          onChange={onSelectChange}
          onClick={selectClick}>
          {statuses.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </CustomSelect>
      </Box>
      <Spacer height="40px" />
      <Button onClick={_trackStatus} isLoading={isLoading} disabled={disabled}>
        Track Status
      </Button>
    </TrackStatusCon>
  );
};
