import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { AdduserCon, InputCon, extraStylesInput } from './style';
import { useEffect, useRef, useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { updateAdminRole } from '../../../network/services/admin/roles';

type UpdateRoleProps = {
  onModalClose: () => void;
  id: number;
  name: string;
};

const INITIAL_STATE = {
  id: 0,
  name: ''
};

export const UpdateRole = ({ id, name, onModalClose }: UpdateRoleProps) => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const queryClient = useQueryClient();
  const nameRef = useRef<HTMLInputElement>(null);
  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const { mutateAsync, isLoading } = useMutationHook(updateAdminRole, 'UPDATE_ROLE');
  const _update = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const newFormData = {
        id,
        roleName: formData.name,
      };
      const { data } = await mutateAsync(newFormData);
      console.log(data.status)
      if (data.status === 'success') {
        queryClient.invalidateQueries('admin_roles');
        onModalClose();
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    nameRef.current!.value = name || '';
  }, [name]);

  return (
    <Modal title={`Update`} maxWidth="500px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Name</span>
          <InputField inputRef={nameRef} extraStyles={extraStylesInput} onChange={onInputChange} name="name" />
        </InputCon>
        <Spacer height="22px" />

        <Spacer height="52px" />

        <Button onClick={_update} isLoading={isLoading} >
          Update
        </Button>
      </AdduserCon>
    </Modal>
  );
};
