import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { AdduserCon, InputCon, extraStylesInput } from '../AddCategory/style';
import { useRef, useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { UploadImageCon } from '../style';
import { UploadIcon } from '../../../assets/Icons';
import { adminUpdateCategory } from '../../../network/services/admin/categories';
// import { addCategory, adminUpdateCategory } from '../../../network/services/admin/categories';

type AddCategoryProps = {
  onModalClose: () => void;
  category: {
    id: number;
    name: string;
    description?: string;
    logo: string | File;
  };
};

// const INITIAL_STATE = {
//   id: 0,
//   name: '',
//   logo: ''
// };

export const UpdateCategory = ({ onModalClose, category }: AddCategoryProps) => {
  const [formData, setFormData] = useState({ id: category.id, name: category.name, logo: category.logo });
  const logoRef = useRef<HTMLInputElement | null>(null);
  const [logo, setLogo] = useState<string | undefined>('');
  const queryClient = useQueryClient();

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const { mutateAsync, isLoading } = useMutationHook(adminUpdateCategory, 'UPDATE_CATEGORY');

  const _update = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const payload = new FormData();
    payload.append('request', new Blob([JSON.stringify({ name: formData.name })], { type: 'application/json' }));
    if(typeof formData.logo !== 'string') {
      payload.append('category_logo', formData.logo);
    }
    try {
      const { data } = await mutateAsync({ formData: payload, categoryId: category.id });
      if (data.status === 'success') {
        queryClient.invalidateQueries('admin_categories');
        onModalClose();
      }
    } catch (error: any) {}
  };

  const handleFileRead = async (e: COMMONTYPES['inputChange']) => {
    const file = e.target.files?.[0];
    setLogo(file?.name);
    setFormData(formData => ({ ...formData, logo: file as File }));
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

  const disabled = !formData.name || !formData.logo;
  return (
    <Modal title="Update" maxWidth="500px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Name</span>
          <InputField value={formData.name} extraStyles={extraStylesInput} onChange={onInputChange} name="name" />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <UploadImageCon>
            <span>Logo</span>
            <div onClick={() => logoRef.current?.click()}>
              <p>{logo || 'Upload File'}</p>
              <UploadIcon />
              <input type="file" ref={logoRef} name="logo" onChange={handleFileRead} required={true} />
            </div>
          </UploadImageCon>
        </InputCon>

        {formData.logo != null &&
          (typeof formData.logo == 'string' ? (
            <div className="img-con">
              <img src={formData.logo} alt="category" />
            </div>
          ) : (
            <div className="img-con">
              <img src={URL.createObjectURL(formData.logo)} alt="category" />
            </div>
          ))}

        <Spacer height="52px" />

        <Button onClick={_update} isLoading={isLoading} disabled={disabled}>
          Save
        </Button>
      </AdduserCon>
    </Modal>
  );
};
