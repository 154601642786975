import { styled } from '@mui/material/styles';
import { Popover } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const CustomPopoverCON = styled(Popover)`
  .popover-content {
    padding: 30px 0;
    width: 160px;
    background-color: white;
    padding: 10px 0px;
    /* box-shadow: none; */
  }
  div {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
  }
`;

export const PopoverContent = styled('div')<{ color?: string }>`
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 10px 16px;

  &:hover {
    background: ${COLORS.MildGray};
  }
 
  p {
    font-size: 14px;
    font-weight: 400;
    color: ${({ color }) => color};
  }
`;
