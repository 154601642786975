import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { BrokenAdd } from '../../../assets/Icons';
import { Container, CustomTable } from '../../../components/atoms';
import { CustomBreadcrumb } from '../../../components/atoms/Breadcrumb';
import { DashboardHeader, TabPanel } from '../../../components/molecules';
import { getShoppingAssistants, getShoppingAssistantsConfigFiles } from '../../../network/services/shoppingAssistant';
import { QUERY_KEYS } from '../../../store/keys';
import CreateShoppingAssistant from '../CreateShoppingAssistant';
import CreateShoppingAssistantFile from '../CreateShoppingAssistantFile';
import { shoppingAssistantColumn, shoppingAssistantConfigFilesColumn } from './data';
import { CustomAuthTab, CustomAuthTabs } from './style';

function ManageShoppingAssistant() {
  const [value, setValue] = useState(0);
  const [isCreateNewAssistant, setIsCreateNewAssistant] = useState<boolean>(false);
  const [isCreateNewAssistantFile, setIsCreateNewAssistantFile] = useState<boolean>(false);
  const { data: shoppingAssistantsData } = useQuery(QUERY_KEYS.SHOPPING_ASSISTANTS, getShoppingAssistants);
  const { data: shoppingAssistantsConfigFilesData } = useQuery(
    QUERY_KEYS.SHOPPING_ASSISTANTS_CONFIG_FILES,
    getShoppingAssistantsConfigFiles
  );

  const shoppingAssistants = useMemo(() => shoppingAssistantsData?.data || [], [shoppingAssistantsData]);
  const shoppingAssistantsConfigFiles = useMemo(
    () => shoppingAssistantsConfigFilesData?.data || [],
    [shoppingAssistantsConfigFilesData]
  );

  const onCreateAssistantModalOpen = () => {
    setIsCreateNewAssistant(true);
  };

  const onCreateAssistantModalClose = () => {
    setIsCreateNewAssistant(false);
  };

  const onCreateAssistantFileModalOpen = () => {
    setIsCreateNewAssistantFile(true);
  };

  const onCreateAssistantFileModalClose = () => {
    setIsCreateNewAssistantFile(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  return (
    <Container>
      <CustomBreadcrumb firstLabel="Shopping Assistants" secondLabel="Management" />
      <DashboardHeader
        title="Manage Assistants"
        buttonPlaceholder="Create Assistant"
        Icon={BrokenAdd}
        onClick={onCreateAssistantModalOpen}
        secondaryButtonPlaceholder="Create Assistant File"
        secondaryOnClick={onCreateAssistantFileModalOpen}
      />
      <CustomAuthTabs value={value} onChange={handleTabChange}>
        <CustomAuthTab label="Assistants" {...a11yProps(0)} />
        <CustomAuthTab label="Files" {...a11yProps(1)} />
      </CustomAuthTabs>
      <TabPanel index={0} value={value}>
        <CustomTable columns={shoppingAssistantColumn} data={shoppingAssistants} />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <CustomTable columns={shoppingAssistantConfigFilesColumn} data={shoppingAssistantsConfigFiles} />
      </TabPanel>
      {isCreateNewAssistant && <CreateShoppingAssistant onModalClose={onCreateAssistantModalClose} />}
      {isCreateNewAssistantFile && <CreateShoppingAssistantFile onModalClose={onCreateAssistantFileModalClose} />}
    </Container>
  );
}

export default ManageShoppingAssistant;
