import { styled } from '@mui/material';
import { COLORS } from '../../theme/color';

export const CustomerSupportCON = styled('div')``;

export const CustomerSupportTableCON = styled('div')`
  background: ${COLORS.White};
`;

export const TableHeaderCON = styled('div')`
  padding: 32px 32px;
`;
export const CustomerStatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) =>
    status?.toLowerCase()?.includes('opened') ? '#FFF6C7' : status?.toLowerCase()?.includes('online') || status?.toLowerCase()?.includes('pending') ? '#1AB66E1F' : '#E2E2E2'};
  color: ${({ status }) =>
    status?.toLowerCase()?.includes('opened') ? '#F79009' : status?.toLowerCase()?.includes('online') || status?.toLowerCase()?.includes('pending') ? '#1AB66E' : '#A8A8A8'};
  display: flex;
  padding: 4px 16px;
  font-weight: 500;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;

export const ActionCON = styled('div')`
  cursor: pointer;
`;

export const extraButtonStyles = {
  color: `${COLORS.MainBlue}`,
  backgroundColor: '#EFF1FB',
  borderRadius: '12px',
  height: '31px',
  width: '102px',
  fontSize: '14px',
  fontWeight: '400'
};
