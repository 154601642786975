import { CustomPopoverCON, PopoverContent } from './style';

type CustomPopoverProps = {
  options?: {
    label: string;
    color?: string;
    Icon?: any;
    handleAction: (item: number) => void;
  }[];
  open: HTMLElement | null;
  handleChange?: any;
  id: string;
  item?: number
};

export const CustomPopover = ({ options, open, handleChange, id, item }: CustomPopoverProps) => {
  const value = Boolean(open);
  return (
    <CustomPopoverCON
      onChange={handleChange}
      open={value}
      id={id}
      disableScrollLock
      disableAutoFocus
      anchorEl={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}>
      <div className="popover-content">
        {options?.map(({ label, Icon, color, handleAction }) => (
          <PopoverContent key={label} onClick={() => handleAction(item ?? 0)} color={color}>
            <Icon />
            <p>{label}</p>
          </PopoverContent>
        ))}
      </div>
    </CustomPopoverCON>
  );
};
