// import { useRecoilState } from 'recoil';
import { Action, BrokenEyeIcon } from '../../../../assets/Icons';
import { CustomPopover } from '../../../../components/atoms';
import { ActionCON } from '../../../style';
import { Modal } from '../../../../components/molecules';
import { OrderDetails } from '../../../../pages';
import { useState } from 'react';

export const AdminCompletedOrderActions = ({ order, status }: any) => {
  const [value, setValue] = useState<HTMLElement | null>(null);
  const [isView, setView] = useState<boolean>(false);

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const handleModalClose = () => {
    setView(false);
  };

  const options = [
    {
      label: 'View details',
      value: 'View details',
      Icon: BrokenEyeIcon,
      handleAction: () => setView(true)
    }
  ];
  return (
    <div>
      <ActionCON aria-describedby="order-action" onClick={event => handleOpen(event)}>
        <Action />
        <CustomPopover item={order} id="order-action" open={value} options={options} />
      </ActionCON>

      {isView && (
        <Modal title="Order Details" maxWidth="500px" onModalClose={handleModalClose}>
          <OrderDetails id={order} />
        </Modal>
      )}
    </div>
  );
};
