// import { extraButtonStyles } from './style';
// import { ActionCON } from '../Product/style';
// import { Button } from '../../components/atoms';
import { LoanAction } from '../../actions';
import { normal_date_convert } from '../../services/format/date';
// import Disburst from './misc/Disburst';
import { LoanStatusCON } from './style';

export const filter = [
  {
    label: 'Loan Application',
    value: 'Application'
  },

  {
    label: 'Loans Disbursed',
    value: 'Disbursed'
  }
];

export const loanApproveOptions = [
  {
    label: 'Admin',
    value: 'Admin'
  },
  {
    label: 'Automatic',
    value: 'Automatic'
  }
];

export const disbursedColumn = [
  {
    id: 1,
    field: 'name',
    headerName: 'Request by',
    flex: 1,
    minWidth: 150
  },

  {
    id: 2,
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'requestDate',
    headerName: 'Request Date',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'disbursementDate',
    headerName: 'Disbursement Date',
    flex: 1,
    minWidth: 100
  }
];

export const loanRequestColumn = [
  {
    id: 1,
    field: 'fullname',
    headerName: 'Request by',
    flex: 2,
    minWidth: 150,
    renderCell: ({ row }: any) => {
      if (row?.customer?.firstname) return <div>{row?.customer?.firstname + ' ' + row?.customer?.lastname}</div>;
    }
  },

  {
    id: 2,
    field: 'loanAmount',
    headerName: 'Amount',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'dateRequested',
    headerName: 'Request Date',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <div>{normal_date_convert(row?.dateRequested)}</div>;
    }
  },

  {
    id: 4,
    field: 'disbursementDate',
    headerName: 'Due Date',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      if (row?.dateRequested) return <div>{normal_date_convert(row?.dateRequested)}</div>;
      return <></>;
    }
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 50,
    renderCell: ({ row }: any) => <LoanAction />
  }
];

export const approvedLoanColumn = [
  {
    id: 1,
    field: 'fullname',
    headerName: 'Request by',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <div>{row?.customer?.firstname + ' ' + row?.customer?.lastname}</div>
  },

  {
    id: 2,
    field: 'loanAmount',
    headerName: 'Amount',
    flex: 1,
    minWidth: 70
  },

  {
    id: 3,
    // field: 'dateRequested',
    headerName: 'Request Date',
    flex: 1,
    minWidth: 70,
    renderCell: ({ row }: any) => {
      if (row?.dateRequested) return <div>{normal_date_convert(row?.dateRequested)}</div>;
      return <></>;
    }
  },

  {
    id: 4,
    field: 'payBackDate',
    headerName: 'Due Date',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      if (row?.dateRequested) return <div>{normal_date_convert(row?.payBackDate)}</div>;
    }
  },

  // {
  //   id: 8,
  //   field: 'action',
  //   headerName: 'Action',
  //   flex: 1,
  //   minWidth: 100,
  //   renderCell: ({ row }: any) => <Disburst row={row} />
  // }
  {
    id: 7,
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <LoanStatusCON status={row.status.toLowerCase()}>
        <p>{row.status}</p>
      </LoanStatusCON>
    )
  },
];

export const loans = [
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  },
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  },
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  },
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  },
  {
    id: 1,
    key: 'name',
    amount: 'N50,000',
    requestDate: '12th January, 2023',
    disbursementDate: '12th January, 2023',
    name: 'Eleanor Pena'
  }
];
