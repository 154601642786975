import { useMediaQuery, Theme } from '@mui/material';
import { TopHeaderCON, IconCon, CardCON, CardDivider } from './style';
// import { TopHeaderCON, IconCon, CardCON, CardDivider, PercentageCON } from './style';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { BrokenUserIcon, BrokenUserTickIcon } from '../../../assets/Icons';
import { getAdminUsersAnalytics } from '../../../network/services/admin/users';
import { useQuery } from 'react-query';

export type cardItemProps = {
  title: string;
  total: number;
  percentage?: string;
  iconBg?: string;
  Icon?: any;
};

export function AdminUsersAnalytics() {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { data } = useQuery(['admin_analytics'], getAdminUsersAnalytics);
  const analytics = data?.data || {};

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1162 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1162, min: 900 },
      items: 2
    },
    small_tablet: {
      breakpoint: { max: 900, min: 800 },
      items: 3
    },
    large_phone: {
      breakpoint: { max: 800, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

  return (
    <div>
      {!md && (
        <>
        <TopHeaderCON gap="">
          <CardCON>
            <div>
              <p>Shoppers</p>
              <h3>{analytics?.shoppers ?? 0}</h3>
            </div>
            <IconCon color="#F5FBEF">
              <BrokenUserIcon />
            </IconCon>
            <CardDivider color="#E9E5E5" />
          </CardCON>

          <CardCON>
            <div>
              <p>Sellers</p>
              <h3>{analytics?.sellers ?? 0}</h3>
            </div>
            <IconCon color="#EFF1FB">
              <BrokenUserTickIcon />
            </IconCon>
            <CardDivider color="#E9E5E5" />
          </CardCON>
          <CardCON>
            <div>
              <p>Businesses</p>
              <h3>{analytics?.business ?? 0}</h3>
            </div>
            <IconCon color="#EFF1FB">
                <BrokenUserTickIcon />
              </IconCon>
              <CardDivider color="#E9E5E5" />
          </CardCON>

          <CardCON>
            <div>
              <p>Admins</p>
              <h3>{analytics?.admin ?? 0}</h3>
            </div>
            <IconCon color="#EFF1FB">
                <BrokenUserTickIcon />
              </IconCon>
          </CardCON>
        </TopHeaderCON>
        <TopHeaderCON gap="40px" width='31rem'>
          <CardCON>
            <div>
              <p>Accountants</p>
              <h3>{analytics?.accountant ?? 0}</h3>
            </div>
            <IconCon color="#F5FBEF">
              <BrokenUserIcon />
            </IconCon>
            <CardDivider color="#E9E5E5" />
          </CardCON>

          <CardCON>
            <div>
              <p>Customer Support</p>
              <h3>{analytics?.customerSupport ?? 0}</h3>
            </div>
            <IconCon color="#EFF1FB">
              <BrokenUserTickIcon />
            </IconCon>
          </CardCON>
        </TopHeaderCON>
        </>
      )}

      {md && (
        <>
        <TopHeaderCON>
          <Carousel autoPlay infinite arrows={false} showDots className="carousel-con" responsive={responsive}>
            <CardCON>
              <div>
                <p>Shoppers</p>
                <h3>{analytics?.shoppers ?? 0}</h3>
              </div>
              <IconCon color="#F5FBEF">
                <BrokenUserIcon />
              </IconCon>
            </CardCON>

            <CardCON>
              <div>
                <p>Sellers</p>
                <h3>{analytics?.sellers ?? 0}</h3>
              </div>
              <IconCon color="#EFF1FB">
                <BrokenUserTickIcon />
              </IconCon>
            </CardCON>
            <CardCON>
            <div>
              <p>Businesses</p>
              <h3>{analytics?.business ?? 0}</h3>
            </div>
            <IconCon color="#EFF1FB">
                <BrokenUserTickIcon />
              </IconCon>
              <CardDivider color="#E9E5E5" />
          </CardCON>

          <CardCON>
            <div>
              <p>Admins</p>
              <h3>{analytics?.admin ?? 0}</h3>
            </div>
            <IconCon color="#EFF1FB">
                <BrokenUserTickIcon />
              </IconCon>
          </CardCON>
          <CardCON>
            <div>
              <p>Accountants</p>
              <h3>{analytics?.accountant ?? 0}</h3>
            </div>
            <IconCon color="#F5FBEF">
              <BrokenUserIcon />
            </IconCon>
            <CardDivider color="#E9E5E5" />
          </CardCON>

          <CardCON>
            <div>
              <p>Customer Support</p>
              <h3>{analytics?.customerSupport ?? 0}</h3>
            </div>
            <IconCon color="#EFF1FB">
              <BrokenUserTickIcon />
            </IconCon>
          </CardCON>
          </Carousel>
        </TopHeaderCON>
        </>
      )}
    </div>
  );
}
