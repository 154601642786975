export const STORE_KEYS = {
  USER_TOKEN_ATOM: '@userTokenAtom',
  USER_ATOM: '@userAtom',
  IS_LOGGED_IN: '@IsLoggedIn',
  ORDER_FILTER: '@orderFilter',
  DELIVERY_FILTER: '@deliveryFilter',
  SUBSCRIPTION_FILTER: '@subscriptionFilter',
  USER_FILTER: '@userFilter',
  GOOD_DETAIL: '@goodsDetails',
  IS_GOOD_OPEN_DETAIL: '@IsgoodsDetailsOpen',
  IS_CART_OPEN: '@IsCartOpen',
  CART_LIST: '@cartList',
  CART_LENGTH: '@cartLength',
  CART_AMOUNT_TOTAL: '@cartAmountTotal',
  WISH_LIST: '@wishList',
  USER_ROLE: '@userRole',
  BUSINESS_KYB: '@businessKYB',
  FILFULMENT_FILTER: '@fulfulmentFilter',
  LOAN_FILTER: '@loanFilter',
  ORDER_ACTION_POPOVER: '@orderActionPopover',
  UPDATE_BUSINESS_AUTH_STEP: '@businessAuthStep',
  CONTACT_INFO: '@contactInfo',
  SELLER_USERS_FILTER: '@sellerUsersFilter',
  SHOPPING_ASSISTANT_ACTION_POPOVER: '@shoppingAssistantActionPopover',
} as const;

export const FILTER_KEYS = {
  SELLERS: 'Sellers',
  BUSINESSES: 'Businesses',
  SHOPPERS: 'Shoppers',
  PENDING_ORDERS: 'Pending',
  ADMINS: 'Admins',
  COMPLETED_ORDER: 'Completed',
  CANCELED_ORDERS: 'Cancelled',
  ALL_ORDERS: 'All',
  DELIVERED_ORDERS: 'Delivered',

  NEW_ORDER: 'New',
  CONFIRMED_FULFILMENT: 'Confirmed',
  AWAITING_FULFILMENT: 'Awaiting',
  LOAN_APPLICATION: 'Application',
  LOAN_DISBURSED: 'Disbursed',
  SELLER_ROLE: 'seller',
  ADMIN_ROLE: 'admin',
  FREE_SUBSCRIPTION: 'Free',
} as const;

export const SELLER_FILTER_KEYS = {
  CUSTOMERS: 'All',
  ACCOUNTANTS: 'Accountants',
  SUBADMIN: 'Sub Admins'
} as const;

export const QUERY_KEYS = {
  USERS_CHATS: 'users_chats',
  ORDER_DETAILS: 'order_details',
  SUBSCRIPTION_PACKAGES: 'subscription_packages',
  SUBSCRIPTION_PACKAGE_DETAILS: 'subscription_package_details',
  SUBSCRIPTION_SUBSCRIBERS: 'subscription_subscribers',
  SHOPPING_ASSISTANTS: 'shopping_assistants',
  SHOPPING_ASSISTANTS_CONFIG_FILES: 'shopping_assistants_config_files',
} as const
