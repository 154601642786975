import { Theme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
// import { NotificationIcon, Menu2Icon } from '../../../assets/Icons';
import { FemaleAvatar, Menu2Icon } from '../../../assets/Icons';

import { LOGIN } from '../../../navigation/routes';
import { userIsLoggedIn } from '../../../store/atoms/userAtom';
// import { Spacer } from '../../atoms';
import { Logo, Spacer } from '../../atoms';
// import { Search } from '../SearchBox';
import { TopNavBarCon } from './styles';
import { userRoleAtom } from '../../../store/atoms/userAtom';
import { useState } from 'react';
import { SideBar } from '../SideBar/SideBar';

export const TopNavBar = () => {
  const [side, setSide] = useState(false);
  const isLogged = useRecoilValue(userIsLoggedIn);
  const userRole = useRecoilValue(userRoleAtom);

  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <>
      <TopNavBarCon>
        <div className="content">
          <div className="left">
            {md && (
              <>
                <div className="mobile">
                  <Menu2Icon onClick={() => setSide(true)} />
                  <Logo />
                </div>

                <Spacer width={8} />
              </>
            )}
            {!md && <Spacer width={50} />}
            {/* {!md && <Search onChange={() => {}} name="search" placeholder="Search" />} */}
          </div>

          <div className="right">
            {/* <NotificationIcon /> */}

            <Spacer width={md ? 25 : 10} />

            <div className="avatar">
              <FemaleAvatar width={50} height={50} />
            </div>

            {!md && <div>Admin</div>}
            {!isLogged && !md && (
              <>
                <Spacer width={10} />
                <Link to={LOGIN} className="link">
                  Sign in / Register
                </Link>
              </>
            )}
          </div>
        </div>

        {md && <SideBar side={side} setSide={setSide} />}
      </TopNavBarCon>
    </>
  );
};
