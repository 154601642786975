import { Box, styled, Tab, Tabs } from '@mui/material';
import { COLORS } from '../../../theme/color';

interface SubscriptionInfoCardProp {
  mode?: 'read-only' | 'edit';
}

export const SubscriptionInfoCard = styled(Box)<SubscriptionInfoCardProp>`
  background-color: ${props => (props.mode === 'read-only' ? COLORS.MainBlue : COLORS.White)};
  color: ${props => (props.mode === 'read-only' ? COLORS.White : COLORS.MainBlack)};
  border-radius: 10px;
  padding: 20px;
  width: ${props => (props.mode === 'read-only' ? "300px" : "250px")};
  min-height: 110px;

  &:hover {
    background-color: ${COLORS.MainBlue};
    color: ${COLORS.White};

    svg {
      fill: ${COLORS.White};
    }
  }

  svg {
    fill: ${props => (props.mode === 'read-only' ? COLORS.White : COLORS.MainBlack)};
  }

  .subscription-info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .subscription-info-header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 400;

    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const SubscriptionInfoValueEditMode = styled('p')`
  font-size: 16px;
  font-weight: 600;
`;

export const SubscriptionInfoValueReadMode = styled('p')`
  background-color: ${COLORS.White};
  color: ${COLORS.MainBlue};
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600;
`;
