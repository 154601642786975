import { useState } from 'react';
import { useQuery } from 'react-query';
import { Container, CustomTable } from '../../components/atoms';
import { getShoppers } from '../../network/services/admin/users';
import { shopperColumn } from './data';
// import { getAllAdminUsers, getShoppers } from '../../network/services/admin/users';
// import { shopperColumn, unverifiedSellerColumn, } from "./data";

export const AdminShoppers = () => {
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data, isFetchedAfterMount } = useQuery(['admin_shoppers', { page: page - 1, size: pageSize }], getShoppers);
  const users = data?.data || [];

  return (
    <Container>
      <CustomTable
        isLoading={isFetchedAfterMount}
        columns={shopperColumn}
        data={users}
        totalItems={data?.totalElements ?? 0}
        totalPages={data?.totalPages ?? 0}
        currentPage={page}
        setCurrentPage={setPage}
      />
    </Container>
  );
};
