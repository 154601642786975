import { styled, Box, Select } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const ProductCON = styled('div')``;

export const AddProductCON = styled('div')``;

export const FormCON = styled('div')`
  background: ${COLORS.White};
  margin: 40px 0px;
  padding: 56px 80px 45px 80px;
  @media (max-width: ${breakpoints.md}px) {
    padding: 56px 10px 45px 10px;
  }
  border-radius: 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const InputCon = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0;
  }
`;

export const extraStylesInput = {
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  width: '100%',
  borderRadius: '16px',
  border: '1px solid #E6E6E6',

  height: '56px',
  opacity: '0.8'
};

export const extraStylesButton = {
  borderRadius: '8px',
  height: '48px',
  padding: '12px 15px',
  width: '300px'
};

export const AltInput = styled(Box)`
  display: flex;
  gap: 40px;
  padding: 8px 0;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const ImageUploadCON = styled(Box)`
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: column;

  p {
    cursor: pointer;
  }
  .selected-file {
    text-align: center;
    color: ${COLORS.MainBlue};
    font-weight: 600;
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0;
  }

  .images-con {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > *:not(:last-child) {
      margin-left: 1.5rem;
    }
  }

  .primary-image-con {
    width: 43%;
  }

  .secondary-images-con {
    width: 43%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }

  .image-upload-con {
    display: flex;
    gap: 20px;

    .image-ref {
      /* width: 300px; */
      background: #f9f9f9;
      display: flex;
      flex: 2;
      height: 242px;
      padding: 12px 18px;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      gap: 10px;
      border: 1px dashed #e6e6e6;

      p {
        color: #a18d8e;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .image-ref-sm {
      background: #f9f9f9;
      display: flex;
      flex: 2;
      height: 170px;
      padding: 12px 18px;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      gap: 10px;
      border: 1px dashed #e6e6e6;
      p {
        color: #a18d8e;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .uploaded-images {
      flex: 2;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      height: 20rem;
      overflow-y: scroll;
    }
  }

  .image {
    position: relative;
    width: 100%;
    max-height: 15rem;

    .delete-icon {
      position: absolute;
      padding: 5px;
      background-color: #ffffff;
      border-color: transparent;
      border-radius: 20%;
      margin: 5px;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const TextEditorCON = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0;
  }
`;

export const CsvUploadCon = styled(Box)`
  display: flex;
  padding: 30px 52px;
  width: 100%;
  flex-direction: column;
`;

export const ActionsCon = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

export const ManualBtnExtraStyles = {
  width: '173px',
  height: '36px',
  borderRadius: '12px'
};

export const CSVBtnExtraStyles = {
  background: 'rgba(47, 77, 196, 0.12)',
  width: 'auto',
  height: '36px',
  borderRadius: '12px',
  color: `${COLORS.MainBlue}`
};

export const CustomSelect = styled(Select)({
  width: '100%',
  height: '56px',
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  borderRadius: '16px',
  border: '1px solid #E6E6E6',
  opacity: '0.8',
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    // border: '1px solid #dbdce9',
    fontFamily: 'Outfit'
  }
});
