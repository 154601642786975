import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { getBackgroundColor, getColor } from '../../../utils/statusColors';

export const OrderDetailsContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .value {
    color: #a18d8e;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const OrderDetailsCON = styled('div')`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

export const StatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) => (status ? getBackgroundColor(status) : '#FFFFFF')};
  color: ${({ status }) => (status ? getColor(status) : '#000000')};
  display: flex;
  padding: 4px 16px;
  font-weight: 500;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;
