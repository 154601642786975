import { styled, Box, Select } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const LoanSettingsCON = styled('div')``;

export const FormCON = styled('div')`
  background: ${COLORS.White};
  padding: 50px 15px;
  h2 {
    line-height: 24px;
    font-size: 18px;
    font-weight: 700;
  }

  @media (max-width: ${breakpoints.md}px) {
    padding: 56px 10px 45px 10px;
  }
  border-radius: 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const InputCon = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    color: #656769;
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0;
  }
  .MuiSelect-root {
    height: 40px;
  }
`;

export const extraStylesInput = {
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  width: '100%',
  borderRadius: '4px',
  border: '1px solid #E6E6E6',

  height: '56px',
  opacity: '0.8'
};

export const extraStylesButton = {
  borderRadius: '8px',
  height: '48px',
  padding: '12px 15px',
  width: '300px'
};

export const AltInput = styled(Box)`
  display: flex;
  gap: 40px;
  padding: 8px 0;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const ImageUploadCON = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0;
  }
  div {
    width: 100%;
    background: #f9f9f9;
    display: flex;
    height: 242px;
    padding: 12px 18px;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 10px;
    border: 1px dashed #e6e6e6;

    p {
      color: #a18d8e;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const TextEditorCON = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0;
  }
`;

export const LoanHeaderCON = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  button {
    width: 150px;
  }
`;

export const CustomSelect = styled(Select)({
  width: '100%',
  height: '56px',
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  borderRadius: '4px',
  background: 'transparent',
  border: '1px solid #E6E6E6',
  opacity: '0.8',
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    // border: '1px solid #dbdce9',
    fontFamily: 'Outfit'
  },
  '.MuiInputBase-input': {
    background: 'transparent'
  }
});

export const InterestDiv = styled('div')`
  background: ${COLORS.White};
  border: 1px 0;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;

  h6 {
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    width: 33%;
  }

  div { 
    width: 33%;
    display: flex;
    justify-content: center;
    span {
      cursor: pointer;
      text-align: center;
    }
  }

  p {
    color: '#483D3D',
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    width: 33%;
  }
  

  @media (max-width: ${breakpoints.md}px) {
    padding: 56px 10px 45px 10px;
  }
  border-radius: 12px;
`;
