import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { AdduserCon, InputCon, extraStylesInput } from './style';
import { useState, useCallback, useEffect } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { createLoanInterest, updateInterestLoan } from '../../../network/services/admin/loan';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { useQueryClient } from 'react-query';

type AddUserprops = {
  data?: any | string;
  onModalClose: () => void;
};

type IFormData = {
  tenure: string;
  percent: string;
  id: string;
};

export const AddInterest = ({ data, onModalClose }: AddUserprops) => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState<IFormData>({ tenure: '', percent: '', id: '' });
  const [formErrors, setFormErrors] = useState<IFormData>({ tenure: '', percent: '', id: '' });

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    let newFormErrors = { ...formErrors };

    if (name === 'percent') {
      if (value.length > 3) {
        newFormErrors[name] = 'Percentage must not be more than 3 digits';
      } else {
        newFormErrors[name] = '';
      }
    }

    if (name === 'tenure') {
      if (Number(value) < 0) {
        newFormErrors[name] = 'Tenure must not be less than 0';
      } else {
        newFormErrors[name] = '';
      }
    }

    setFormErrors(newFormErrors);
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const { mutateAsync: mutateAsyncCreateLoanInterest, isLoading: createLoanInterestIsLoading } = useMutationHook(
    createLoanInterest,
    'CREATE_LOAN_INTEREST'
  );
  const { mutateAsync: mutateAsyncUpdateLoanInterest, isLoading: updateLoanInterestIsLoading } = useMutationHook(
    updateInterestLoan,
    'UPDATE_LOAN_INTEREST'
  );

  const _update_create_interest = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {

      if (data.id) {
        await mutateAsyncUpdateLoanInterest({ ...formData, tenure: String(formData.tenure) + ' month(s)' });
      } else {
        await mutateAsyncCreateLoanInterest({ ...formData, tenure: String(formData.tenure) + ' month(s)' });
      }
      queryClient.invalidateQueries('loan_interest');
      setFormData(prev => ({ ...prev, id: '', tenure: '', percent: '' }));
      onModalClose();
    } catch (error: any) {}
  };

  const onLoad = useCallback(() => {
    if (typeof data !== 'string') {
      const { id, percent, tenure } = data;
      setFormData(prev => ({ ...prev, id, percent, tenure }));
    }
  }, [data]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const disabled = !formData.tenure || !formData.percent || !!formErrors.tenure || !!formErrors.percent;

  return (
    <Modal
      title="Add Interest"
      maxWidth="500px"
      onModalClose={() => {
        setFormData(prev => ({ ...prev, id: '', tenure: '', percent: '' }));
        onModalClose();
      }}>
      <AdduserCon>
        <Spacer height="22px" />

        <InputCon>
          <span>Tenure</span>
          <InputField
            type="number"
            name="tenure"
            placeholder="3"
            value={formData.tenure}
            onChange={onInputChange}
            extraStyles={extraStylesInput}
            error={formErrors.tenure}
            required={true}
          />
        </InputCon>

        <Spacer height="22px" />

        <InputCon>
          <span>Percentage</span>
          <InputField
            type="number"
            name="percent"
            placeholder="30%"
            value={formData.percent}
            onChange={onInputChange}
            extraStyles={extraStylesInput}
            error={formErrors.percent}
            required={true}
          />
        </InputCon>

        <Spacer height="52px" />

        <Button
          onClick={_update_create_interest}
          isLoading={createLoanInterestIsLoading || updateLoanInterestIsLoading}
          disabled={disabled}>
          Add
        </Button>
      </AdduserCon>
    </Modal>
  );
};
