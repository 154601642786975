import { GridRenderCellParams } from '@mui/x-data-grid';
import { ProductActions } from '../../actions/Admin/Product';
import { BrokenGraph, BrokenBox, BrokenGclose, AdminUsersIcon } from '../../assets/Icons';
import { formatCurrency } from '../../utils/currencyFormater';
import { truncateCharCount } from '../../utils/truncate';
import { ActionCON, ProductStatusCON, ProductImgCon } from './style';
export const topCardData = [
  {
    title: 'No of products',
    total: 338,
    Icon: BrokenGraph,
    iconBg: '#F5FBEF'
  },
  {
    title: 'Products Out of Stock',
    total: 338,
    Icon: BrokenBox,
    iconBg: '#FEF9EC'
  },

  {
    title: 'Total Customers',
    total: 5372,
    Icon: AdminUsersIcon,
    iconBg: '#EFF1FB'
  },

  {
    title: 'Cart Abandonment',
    total: 2375,
    Icon: BrokenGclose,
    iconBg: '#FFF0F3'
  }
];

export const columns = [
  {
    id: 1,
    field: 'id',
    headerName: 'S/No',
    flex: 3,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
  },

  {
    id: 1,
    field: 'name',
    headerName: 'Product Name',
    flex: 3,
    minWidth: 300,
    renderCell: ({ row }: any) => (
      <ActionCON>
        <ProductImgCon>
          {row?.productImage1 && <img src={row.productImage1} alt="product" />}
          <p>{truncateCharCount(row.productName, 20)}</p>
        </ProductImgCon>
      </ActionCON>
    )
  },

  {
    id: 2,
    field: 'category',
    headerName: 'Category',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{row?.category?.name}</p>
  },

  {
    id: 3,
    field: 'quantityAvailable',
    headerName: 'Quantity',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'price',
    headerName: 'Price',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => {
      return <p>{formatCurrency(row.price)}</p>;
    }
  },

  {
    id: 5,
    field: 'status',
    headerName: 'status',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) =>
      row?.status ? (
        <ProductStatusCON status={row?.status}>
          <p>{row?.status}</p>
        </ProductStatusCON>
      ) : (
        <ProductStatusCON status={row?.quantityAvailable > 0 ? 'available' : 'unavailable'}>
          <p>{row?.quantityAvailable > 0 ? 'In Stock' : 'Out of stock '}</p>
        </ProductStatusCON>
      )
  },

  {
    id: 6,
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <ProductActions product={row.id} />;
    }
  }
];
