import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminUsersIcon, BoxOrangeIcon, SettingGearIcon } from '../../assets/Icons';
import { Container, Spacer } from '../../components/atoms';
import { DashboardHeader, TopHeaderCard } from '../../components/molecules';
import CreateShoppingAssistant from './CreateShoppingAssistant';

function ShoppingAssistant() {
  const navigate = useNavigate();

  const topCardData = [
    {
      title: 'Assisted Shoppers',
      total: 2372,
      Icon: () => <AdminUsersIcon stroke="#2F4DC4" />,
      iconBg: '#EFF1FB'
      // percentage: '+35.5%'
    },

    {
      title: 'Completed Assistance',
      total: 2543,
      Icon: BoxOrangeIcon,
      iconBg: '#FEF9EC'
      // percentage: '-3.93%',
    },

    {
      title: 'Users on subscription',
      total: 2412,
      // percentage: '+25.3%',
      Icon: () => <AdminUsersIcon stroke="#669F2A" />,
      iconBg: '#F0F5EA'
    },

    {
      title: 'Users on free plan',
      total: 2764,
      // percentage: '-26.3%',
      Icon: () => <AdminUsersIcon stroke="#F04438" />,
      iconBg: '#FEECEB'
    }
  ];

  return (
    <Container>
      <DashboardHeader
        title="Shopping Assistant"
        buttonPlaceholder="Manage Assistants"
        Icon={SettingGearIcon}
        onClick={() => {
          navigate('management');
        }}
      />
      <TopHeaderCard data={topCardData.slice(0, 4)} />
      <Spacer height="48px" />
    </Container>
  );
}

export default ShoppingAssistant;
