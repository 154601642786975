import { useMutation } from 'react-query';
import { ResponseContent } from '../../TYPES/response';
// import { enqueueSnackbar } from 'notistack';
import { MessageProp, apiResponse } from '../config/message.constants';

export const useMutationHook = <T extends unknown>(
  request: (payload: T) => Promise<ResponseContent<any>>,
  operation?: MessageProp
) => {
  const mutate = useMutation(request, {
    onSuccess: async data => {
      apiResponse(data, 'success', operation);
      // console.log(data);
      return;
    },
    onError: (error: Record<any, any>) => {
      apiResponse(error, 'error', operation);
      // console.log(error.response);
      return;
    }
  });
  // const { mutateAsync, status, data } = mutate;
  // return { mutateAsync, status, data };
  return mutate;
};
