import { styled, Box, Select } from '@mui/material';

export const SubscriptionPackageDetailsCON = styled(Box)`
  padding: 50px 0px 10px;
  h3 {
    font-weight: 600;
    font-size: 20px;
  }
`;

export const SubscriptionPackageDescriptionCON = styled(Box)`
  padding: 10px 20px;
  font-weight: 400;
  font-size: 18px;
  ul {
    padding: 0px 20px;
  }
`;
