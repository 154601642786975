import { PlusAddIcon } from '../../../assets/Icons';
import { ChatSendIcon } from '../../../assets/Icons';
import { InputField } from '../../atoms';
import { ChatBoxCON, ChatInput, MessageBox } from './style';

interface IChatType {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  extraStyles?: Record<string, string>;
  value?: string;
  name: string;
  chatMessageInputRef?: React.RefObject<HTMLInputElement>;
  handleSendMessage: () => void;
}

export const ChatBox = ({
  name,
  disabled,
  extraStyles,
  value,
  onChange,
  onKeyDown,
  chatMessageInputRef,
  handleSendMessage
}: IChatType) => {
  return (
    <ChatBoxCON style={extraStyles}>
      <PlusAddIcon />
      <MessageBox>
        <InputField
          name={name}
          inputRef={chatMessageInputRef}
          type="search"
          placeholder="Send a message"
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
          value={value}
          extraStyles={ChatInput}
        />
        <ChatSendIcon onClick={handleSendMessage} tabIndex={0} />
      </MessageBox>
    </ChatBoxCON>
  );
};
