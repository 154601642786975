/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProductUpload } from '../../../TYPES/api.body';
import { ProductAnalytics, ProductsPaginatedResponse, ResponseContent } from '../../../TYPES/response';
import {
  ADMIN_DELETE_PRODUCT,
  ADMIN_MANUAL_PRODUCT_UPLOAD,
  ADMIN_UPDATE_PRODUCT,
  GET_ADMIN_PRODUCTS,
  GET_ADMIN_PRODUCT_ANALYTICS
} from '../../config/endpoints';
import api from '../../config/setAuthHeader';

export const getAdminProducts = async ({ queryKey }: any): Promise<ProductsPaginatedResponse> => {
  const [_, payload] = queryKey;
  const { page, size } = payload;
  const { data } = await api.get(`${GET_ADMIN_PRODUCTS}?page=${page}&size=${size}`);
  return data;
};

export const getAdminProductAnalytics = async (): Promise<ResponseContent<ProductAnalytics>> => {
  const { data } = await api.get(GET_ADMIN_PRODUCT_ANALYTICS);
  return data;
};

export const adminManualProductUpload = async (payload: FormData) => {
  const { data } = await api.post(ADMIN_MANUAL_PRODUCT_UPLOAD, payload);
  return data;
};

export const deleteProduct = async (product: number) => {
  const data = await api.delete(`${ADMIN_DELETE_PRODUCT}/${product}`);
  return data;
};

interface UpdateProductRequestType {
  formData: FormData;
  productId: string;
}

export const adminUpdateProduct = async (payload: UpdateProductRequestType) => {
  const { productId, formData } = payload;
  const { data } = await api.put(`${ADMIN_UPDATE_PRODUCT}/${productId}`, formData);
  return data;
};
