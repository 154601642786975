import { useRecoilState } from 'recoil';
import { Action, BrokenGclose, CheckIcon } from '../../assets/Icons';
import { Button, CustomPopover } from '../../components/atoms';
import { ActionCON, DeclineLoanCON, InputCON, StyledTextarea, extraSubmitBtnStyles } from '../style';
import { declineLoanAction } from '../../store/atoms/modalAtoms';
import { Modal } from '../../components/molecules/Modal/Modal';
import { actionPopover } from '../../store/atoms/shoppingAssistantAtom';

export const ShoppingAssistantAction = () => {
  const [value, setValue] = useRecoilState(actionPopover);
  const [isOpen, setIsOpen] = useRecoilState(declineLoanAction);

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const options = [
    {
      label: 'View details',
      value: 'View details',
      Icon: CheckIcon,
      color: '#27AE60',
      handleAction: () => {}
    },

    {
      label: 'Edit',
      value: 'Edit',
      Icon: CheckIcon,
      color: '#27AE60',
      handleAction: handleOpenModal
    },

    {
      label: 'Delete',
      value: 'Delete',
      Icon: BrokenGclose,
      color: '#D90429',
      handleAction: () => {}
    }
  ];
  return (
    <ActionCON aria-describedby="order-actions" onClick={event => handleOpen(event)}>
      <Action />
      <CustomPopover id="order-actions" open={value} options={options} />
      {isOpen && (
        <Modal maxWidth="400px" onModalClose={handleModalClose}>
          <DeclineLoanCON>
            <h3>Decline Request</h3>
            <InputCON>
              <span>Reason for Decline</span>
              <StyledTextarea minRows={4} />
            </InputCON>
            <div className="button-container">
              <Button extraStyles={extraSubmitBtnStyles}>Decline</Button>
            </div>
          </DeclineLoanCON>
        </Modal>
      )}
    </ActionCON>
  );
};
