// import { useQueryClient } from 'react-query';
// import { useQuery, useQueryClient } from 'react-query';
import { Button } from '../../../components/atoms';
// import { useMutationHook } from '../../../network/mutations/useMutationHook';
// import { getBusinessDetails, verifySeller } from '../../../network/services/admin/users';
import { ActionCON, extraButtonStyles } from '../../style';
// import { Modal } from '../../../components/molecules/Modal/Modal';
import { useState } from 'react';
import { BusinessDetails } from '../../../pages/User/Details';

export const VerifyBusinessAction = ({ id }: any) => {
  // const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const _handleViewDetails = () => {
    setIsOpen(true);
  };
  return (
    <ActionCON>
      <Button onClick={_handleViewDetails} extraStyles={extraButtonStyles}>
        View
      </Button>

      {isOpen && <BusinessDetails id={id} onModalClose={handleModalClose} />}
    </ActionCON>
  );
};
