import { styled } from '@mui/material';
import { COLORS } from '../../theme/color';

export const TopHeaderSubscriptionCON = styled('div')<{ gap?: string }>`
  padding: 20px 0px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;
`;

export const SubscriptionTableCON = styled('div')`
  background: ${COLORS.White};
`;

export const TableHeaderCON = styled('div')`
  padding: 32px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubscriptionStatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) => (status?.toLowerCase().includes('expired') ? '#FFF6C7' : '#F0F5EA')};
  color: ${({ status }) => (status?.includes('expired') ? '#F79009' : '#669F2A')};
  display: flex;
  padding: 4px 16px;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;