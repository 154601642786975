export const JOIN_WAITING_LIST = '/api/v1/auth/customer-email';
// Auth
export const LOGIN = '/api/v1/seller/auth/login';
export const REGISTER = '/api/v1/seller/auth/register';
export const VERIFY_OTP = 'api/v1/seller/auth/verify';
export const REQUEST_OTP = `/api/v1/customer/auth/resend-otp`;
export const RESET_PASSWORD = '/api/v1/customer/auth/reset-password';
export const NEW_PASSWORD = '/api/v1/customer/auth/new-password';
export const LOGOUT = '/api/v1/auth/logout';
// Profile
export const GET_CUSTOMER = '/api/v1/customer/get_customer';
export const UPDATE_CUSTOMER = '/api/v1/customer/update/1';
export const DELETE_CUSTOMER = '/api/v1/customer/1';
export const CHANGE_PASSWORD = '/api/v1/customer/change_password';
export const UPDATE_PIC = '/api/v1/customer/update_profile_picture/1';
// Address
export const GET_ALL_ADDRESSES = '/api/v1/customer/addresses';
export const ADD_ADDRESS = '/api/v1/customer/add_address';
export const DELETE_ADDRESS = '/api/v1/customer/address/';
export const GET_SINGLE_ADDRESS = '/api/v1/customer/address/';
export const UPDATE_ADDRESS = '/api/v1/customer/address/update/';
export const SET_DEFAULT_ADDRESS = '/api/v1/customer/address/set_default_address/';
// product or goods
export const GET_ALL_PRODUCT = '/api/v1/customer/fetch_all_product';
export const MANUAL_PRODUCT_UPLOAD = '/api/v1/seller/product/manual/store';
export const DELETE_PRODUCT = '/api/v1/seller/product/delete';
export const UPDATE_PRODUCT = '/api/v1/seller/product/upadate';
export const PRODUCT_DETAILS = '/api/v1/admin/product';
//categories
export const GET_ALL_CATEGORIES = '/api/v1/admin/category';
export const GET_SUB_CATEGORIES = '/api/v1/admin/category/subcategories';
// Seller
export const GET_SELLER_PRODUCTS = '/api/v1/seller/product/seller_products';
export const GET_SELLER_USERS = '/api/v1/seller/user';
export const GET_SELLER_WALLET_BALANCE = '/api/v1/seller/wallet/balance';
export const GET_SELLER_WALLET_HISTORIES = '/api/v1/seller/wallet/histories';
export const GET_SELLER_ROLES = '/api/v1/seller/role/roles';
export const DELETE_SELLER_PRODUCT = '/api/v1/seller/product/delete';
export const GET_LOCATION = '/api/v1/seller/role/locations';
export const ADD_USER = '/api/v1/seller/user/register';
export const UPLOAD_CSV_PRODUCTS = '/api/v1/seller/product/cvs_upload/store';

// Admin
export const ADMIN_LOGIN = '/api/v1/admin/auth/login';
//Categories
export const GET_CATEGORIES = '/api/v1/categories';
export const ADD_CATEGORY = '/api/v1/admin/category/add';
export const ADD_SUB_CATEGORY = '/api/v1/admin/category/add_subcategory';
export const ADMIN_DELETE_CATEGORY = '/api/v1/admin/category/delete_category';
export const ADMIN_UPDATE_CATEGORY = '/api/v1/admin/category/update';
//Admin Product
export const GET_ADMIN_PRODUCTS = 'api/v1/admin/product/all';
export const GET_ADMIN_PRODUCT_ANALYTICS = 'api/v1/admin/product/analytics';
export const ADMIN_MANUAL_PRODUCT_UPLOAD = '/api/v1/admin/product/manual/store';
export const ADMIN_DELETE_PRODUCT = '/api/v1/admin/product/delete';
export const ADMIN_UPDATE_PRODUCT = '/api/v1/admin/product/update';

//Admin orders
export const ADMIN_GET_ALL_ORDERS = '/api/v1/admin/order/view_order/';
export const GET_ADMIN_USERS = '/api/v1/admin/user/all';
export const GET_ADMIN_ORDER_ANALYTICS = '/api/v1/admin/order/analytics';
export const GET_ADMIN_ORDER_DETAILS = '/api/v1/admin/order/view_single_order';
export const ADMIN_CANCEL_ORDER = '/api/v1/admin/order/';
export const ADMIN_TRACK_STATUS = '/api/v1/admin/order/change_order_status';
export const GET_ADMIN_ORDER_STATISTICS = '/api/v1/admin/order/order-report';
export const GET_ADMIN_SALES_STATISTICS = '/api/v1/admin/order/sales-report';
export const GET_ADMIN_TOTAL_PROFITS_STATISTICS = '/api/v1/admin/order/total-profit';
export const ADMIN_GET_ORDER_DETAILS = 'api/v1/customer/cart/get_order';

// Admin users
export const GET_SHOPPERS = '/api/v1/admin/user/shoppers';
export const GET_SELLERS = '/api/v1/admin/user/sellers';
export const ADMIN_ADD_USER = '/api/v1/admin/user/register';
export const GET_BUSINESS = '/api/v1/admin/user/businesses';
export const GET_ADMINS = '/api/v1/admin/user/admins';
export const ADMIN_GET_BUSINESS_DETAILS = '/api/v1/admin/user/business_by_id';
export const ADMIN_DELETE_USER = '/api/v1/admin/user/remove_admin';
export const ADMIN_DELETE_SHOPPER_BUSINESS = '/api/v1/admin/user/remove_shopper';
export const ADMIN_CHANGE_ROLE = '/api/v1/seller/user/change_role';
export const ADMIN_VERIFY_SELLER = '/api/v1/admin/user/verify_seller';
export const ADMIN_VERIFY_BUSINESS = '/api/v1/admin/user/verify_business';
export const GET_ADMIN_USERS_ANALYTICS = 'api/v1/admin/user/user_analytics';
export const GET_ADMIN_OVERVIEW = '/api/v1/admin/user/overview_analytics';

// Admin Deliveries
export const GET_ADMIN_DELIVERIES = 'api/v1/admin/delivery/view_delivery/all';
export const GET_ADMIN_DELIVERY = '/api/v1/admin/delivery/analytics';

// Admin roles
export const ADD_ROLE = '/api/v1/admin/role/addRole';
export const GET_ADMIN_ROLES = '/api/v1/admin/role/roles';
export const ADMIN_UPDATE_ROLE = '/api/v1/admin/role/update';
export const ADMIN_CHANGE_USER_ROLE = '/api/v1/admin/product/delete';
export const ADMIN_DELETE_ROLE = '/api/v1/admin/role/delete';
//Admin Logistics
export const ADMIN_GET_ALL_LOGISTICS = '/api/v1/admin/delivery_agent/all';
export const ADMIN_ADD_LOGISTICS = '/api/v1/admin/delivery_agent/add_new';
export const ADMIN_UPDATE_LOGISTICS = '/api/v1/admin/delivery_agent/update';
export const ADMIN_DELETE_LOGISTICS = '/api/v1/admin/delivery_agent/delete';


// Admin Loans
export const ADMIN_UPDATE_LOAN_SETTING = '/api/v1/admin/loan/update-loan-settings'
export const ADMIN_DELETE_LOAN_TENURE = '/api/v1/admin/loan_tenure/delete'
export const ADMIN_CREATE_LOAN_INTEREST = '/api/v1/admin/loan_tenure/add_new'
export const ADMIN_UPDATE_LOAN_INTEREST = '/api/v1/admin/loan_tenure/update'

// Admin Chat
export const ADMIN_GET_PREVIOUS_MESSAGES = '/messages';
export const ADMIN_GET_USERS_CHAT = '/users';

// Admin Subscriptions
export const ADMIN_GET_SUBSCRIPTIONS = '/api/v1/admin/subscription-packages';
export const ADMIN_GET_SUBSCRIPTION_PACKAGE_DETAILS = '/api/v1/admin/subscription-packages';
export const ADMIN_CREATE_SUBSCRIPTION_PACKAGE = '/api/v1/admin/subscription-packages/create';
export const ADMIN_UPDATE_SUBSCRIPTION_PACKAGE = '/api/v1/admin/subscription-packages';
export const ADMIN_GET_SUBSCRIBERS = '/api/v1/madina-plus/subscribers';

// Admin AI Shopping Assistants
export const ADMIN_GET_SHOPPING_ASSISTANTS = '/api/v1/openai/get-assistants';
export const ADMIN_GET_SHOPPING_ASSISTANTS_CONFIG_FILES = '/api/v1/openai/get-added-files';
export const ADMIN_CREATE_SHOPPING_ASSISTANT = '/api/v1/openai/create-assistant';
export const ADMIN_CREATE_SHOPPING_ASSISTANT_FILE = '/api/v1/openai/create-assistant-file';
export const ADMIN_LINK_ASSISTANT_TO_FILE = '/api/v1/openai/link-file';