import React from 'react';
import { Box, Drawer, List, ListItemText, Divider } from '@mui/material';
// import { Box, Drawer, List, ListItemButton, ListItemText, Divider } from '@mui/material';
import { CustomListItemButton, DrawerHeader, ImgBox, headTextStyle, textStyle } from './styles';
import { FILTER_KEYS } from '../../../store/keys';
import { Logo, Spacer } from '../../atoms';
import { userRoleAtom } from '../../../store/atoms/userAtom';
import { CancelIcon } from '../../../assets/Icons';
import { adminNavs } from '../../../navigation/DashboardRoutes/navs';

import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

type SideBarProp = {
  side: boolean;
  setSide: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SideBar = ({ side, setSide }: SideBarProp) => {
  const navigate = useNavigate();

  const userRole = useRecoilValue(userRoleAtom);

  const _onRouting = (to: string) => {
    setSide(false);
    navigate(to);
  };

  const { pathname } = useLocation();

  const toggleDrawer = (open: any) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setSide(!side);
  };

  const { ADMIN_ROLE } = FILTER_KEYS;
  return (
    <Drawer
      anchor="left"
      open={side}
      onClose={toggleDrawer(false)}
      sx={{ '.MuiDrawer-paper': { backgroundColor: '#fff' } }}>
      <Spacer height={20} />

      <DrawerHeader>
        <Logo />
        <Spacer width={50} />
        <CancelIcon onClick={() => setSide(false)} />
      </DrawerHeader>
      <Spacer height={30} />

      <Divider />
      <List>
        <ListItemText sx={headTextStyle} />
        {userRole.includes(ADMIN_ROLE) &&
          adminNavs.map(({ label, Icon, to }) => (
            <CustomListItemButton
              isActive={pathname.includes(to) ? true : false}
              key={label}
              onClick={() => _onRouting(to)}>
              <Box sx={ImgBox}>
                <Icon />
              </Box>
              <ListItemText primary={label} sx={textStyle} />
              <Spacer height="50px" />
            </CustomListItemButton>
          ))}
      </List>
    </Drawer>
  );
};
