import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { AdduserCon, InputCon, extraStylesInput, extraStylesCheckbox } from './style';
import { useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';

// import { addNewUser, getLocation, getRoles } from '../../../network/services/users';
import { useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { QUERY_KEYS } from '../../../store/keys';
import {
  createSubscriptionPackage,
  SubscriptionPackage,
  updateSubscriptionPackage
} from '../../../network/services/subscriptions';

type AdminCreateUpdateSubscriptionProps = {
  onModalClose: () => void;
  currentSubscription: SubscriptionPackage | null;
  mode?: 'create' | 'update';
};

const INITIAL_STATE: SubscriptionPackage = {
  name: '',
  duration: 1,
  amount: 0,
  benefits: [''],
  legibleForFreeTrial: false
};

const AdminCreateUpdateSubscription = ({
  onModalClose,
  currentSubscription,
  mode = 'create'
}: AdminCreateUpdateSubscriptionProps) => {
  const [formData, setFormData] = useState(
    mode === 'update' && currentSubscription ? currentSubscription : INITIAL_STATE
  );
  // const { data } = useQuery('user_roles', getRoles);
  const queryClient = useQueryClient();

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    if (name === 'benefits') {
      setFormData(formData => ({ ...formData, [name]: [value] }));
    } else {
      setFormData(formData => ({ ...formData, [name]: value }));
    }
  };

  const onCheckboxChange = (e: COMMONTYPES['inputChange']) => {
    const { name, checked } = e.target;
    setFormData(formData => ({ ...formData, [name]: checked }));
  };

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } = useMutationHook(
    createSubscriptionPackage,
    'CREATE_SUBSCRIPTION_PACKAGE'
  );
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = useMutationHook(
    updateSubscriptionPackage,
    'UPDATE_SUBSCRIPTION_PACKAGE'
  );

  const _createSubscriptionPackage = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (mode === 'create') {
      try {
        const { data } = await mutateAsyncCreate({ ...formData, ...(formData.id && { id: formData.id }) });
        if (data.status === 'success') {
          queryClient.invalidateQueries(QUERY_KEYS.SUBSCRIPTION_PACKAGES);
          onModalClose();
        }
      } catch (error: any) {}
    } else {
      try {
        const { data } = await mutateAsyncUpdate(formData);
        if (data.status === 'success') {
          queryClient.invalidateQueries(QUERY_KEYS.SUBSCRIPTION_PACKAGES);
          onModalClose();
        }
      } catch (error: any) {}
    }
  };

  const disabled = !formData.name || !formData.amount || !formData.duration;
  return (
    <Modal title={mode === "create" ? "Create New Subscription" : "Update Subscription Details"} maxWidth="700px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Package Name</span>
          <InputField extraStyles={extraStylesInput} value={formData.name} onChange={onInputChange} name="name" />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Amount</span>
          <InputField
            type="number"
            extraStyles={extraStylesInput}
            value={formData.amount}
            onChange={onInputChange}
            name="amount"
          />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Duration</span>
          <InputField
            type="number"
            extraStyles={extraStylesInput}
            value={formData.duration}
            onChange={onInputChange}
            name="duration"
          />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Benefits</span>
          <InputField
            extraStyles={extraStylesInput}
            value={formData.benefits[0]}
            onChange={onInputChange}
            name="benefits"
          />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Legible For Free Trial</span>
          <InputField
            type="checkbox"
            extraStyles={extraStylesCheckbox}
            defaultChecked={formData.legibleForFreeTrial}
            onChange={onCheckboxChange}
            name="legibleForFreeTrial"
            width="2rem"
          />
        </InputCon>

        <Spacer height="52px" />

        <Button onClick={_createSubscriptionPackage} isLoading={isLoadingCreate ?? isLoadingUpdate} disabled={disabled}>
          {mode === 'create' ? 'Create Package' : 'Update Package'}
        </Button>
      </AdduserCon>
    </Modal>
  );
};

export default AdminCreateUpdateSubscription;
