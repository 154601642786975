import { Action, Edit2Icon, BrokenGclose } from '../../../assets/Icons';
import { CustomPopover } from '../../../components/atoms';
import { ActionCON } from '../../style';
import { useState } from 'react';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { useQueryClient } from 'react-query';
import { UpdateLogistics } from '../../../pages';
import { deleteLogistics } from '../../../network/services/admin/logistics';

export const LogisticsAction = ({ logistics }: any) => {
  const [value, setValue] = useState<HTMLElement | null>(null);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const _update = (id?: any) => {
    setUpdate(true);
  };

  const { mutateAsync } = useMutationHook(deleteLogistics, 'DELETE_LOGISTICS');

  const _delete = async (id: number) => {
    try {
      await mutateAsync(id);
      queryClient.invalidateQueries('admin_logistics');
    } catch (error: any) {}
  };

  const options = [
    {
      label: 'Update',
      value: 'Update',
      Icon: Edit2Icon,
      handleAction: _update
    },

    {
      label: 'Delete',
      value: 'Delete',
      Icon: BrokenGclose,
      color: '#D90429',
      handleAction: _delete
    }
  ];

  return (
    <div>
      <ActionCON aria-describedby="logistics-actions" onClick={event => handleOpen(event)}>
        <Action />
        <CustomPopover item={logistics.id} id="logistics-actions" open={value} options={options} />
        {isUpdate && <UpdateLogistics name={logistics.name} id={logistics.id} onModalClose={() => setUpdate(false)} />}
      </ActionCON>
    </div>
  );
};
