import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { AdduserCon, InputCon, extraStylesInput } from './style';
import { useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { addRole } from '../../../network/services/admin/roles';

type AddRoleProps = {
  loan_id: string;
  onModalClose: () => void;
};

const INITIAL_STATE = {
  roleName: ''
};

const ViewLoan = ({ onModalClose, loan_id }: AddRoleProps) => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const queryClient = useQueryClient();

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const { mutateAsync, isLoading } = useMutationHook(addRole, 'ADD_ROLE');

  const _AddRole = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const { data } = await mutateAsync(formData);
      if (data.status === 'success') {
        queryClient.invalidateQueries('admin_roles');
        onModalClose();
      }
    } catch (error: any) {}
  };

  const disabled = !formData.roleName;
  return (
    <Modal title="Add Role" maxWidth="500px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Role Name</span>
          <InputField extraStyles={extraStylesInput} onChange={onInputChange} name="roleName" />
        </InputCon>
        <Spacer height="52px" />

        <Button onClick={_AddRole} isLoading={isLoading} disabled={disabled}>
          Add
        </Button>
      </AdduserCon>
    </Modal>
  );
};

export default ViewLoan;
