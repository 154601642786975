import { styled } from '@mui/material';

export const ChatBoxCON = styled('div')`
  display: flex;
  gap: 20px;
  /* justify-content: space-between; */
  align-items: center;
  border-radius: 8px;
  width: 100%;
  svg {
    cursor: pointer;
  }
`;

export const MessageBox = styled('div')`
  background: #fafafa;
  position: relative;
  border-radius: 20px;
  width: 90%;
  svg {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    z-index: 10;
  }
`;

export const ChatInput = {
  paddingRight: '4rem',
  fontSize: '0.9rem',
  border: '1px solid #FAFAFA',
  background: '#FAFAFA',
  height: '60px',
  width: '700px',
};

export const extraStylesBtn = {
  height: '40px'
};
