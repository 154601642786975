import { isAxiosError } from 'axios';
import api from '../../config/setAuthHeader';
import { ADMIN_CREATE_LOAN_INTEREST, ADMIN_DELETE_LOAN_TENURE, ADMIN_UPDATE_LOAN_INTEREST, ADMIN_UPDATE_LOAN_SETTING } from '../../config/endpoints';

export const get_loans_analytics = async () => {
  try {
    const response = await api.get('/api/v1/admin/loan/analytics');

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
      return { data: [], status: false };
    }
  }
};

export const get_loans = async () => {
  try {
    const response = await api.get('/api/v1/admin/loan/get_loans/all');

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
      return { data: [], status: false };
    }
  }
};

export const get_loan_disbursed = async () => {
  try {
    const response = await api.get('/api/v1/admin/loan/get_loans/disbursed');

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
      return { data: [], status: false };
    }
  }
};

export const get_setting = async () => {
  try {
    const response = await api.get('/api/v1/admin/loan/loan_setting_details');

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
      // return { data: [], status: false };
    }
  }
};

export const get_interest = async () => {
  try {
    const response = await api.get('/api/v1/admin/loan_tenure/all');

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const updateSetting = async (data: any) => {
  return await api.put(ADMIN_UPDATE_LOAN_SETTING, data); 
};

export const updateInterestLoan = async (data: any) => {
  return await api.put(`${ADMIN_UPDATE_LOAN_INTEREST}/${data?.id}`, data);
};

export const createLoanInterest = async (data: any) => {
  return await api.post(ADMIN_CREATE_LOAN_INTEREST, data);
};

export const deleteSetting = async (id: string) => {
  return await api.delete(`${ADMIN_DELETE_LOAN_TENURE}/${id}`);
};
