import { AAddRole, AChangeUserRole, AUpdateRole } from '../../../TYPES/api.body';
import { ADD_ROLE, ADMIN_CHANGE_ROLE, ADMIN_DELETE_ROLE,  ADMIN_UPDATE_ROLE,  GET_ADMIN_ROLES } from '../../config/endpoints';
import api from '../../config/setAuthHeader';

export const getAdminRoles = async () => {
  const { data } = await api.get(`${GET_ADMIN_ROLES}`);
  return data;
};

export const addRole = async (formData: AAddRole) => {
  return await api.post(ADD_ROLE, formData);
};


export const deletRole = async (role: number) => {
  const { data } = await api.delete(`${ADMIN_DELETE_ROLE}/${role}`);
  return data;
};


export const updateAdminRole = async (formData: AUpdateRole) => {
  return await api.patch(`${ADMIN_UPDATE_ROLE}/${formData.id}`, formData);
};

export const changeUserRole = async (formData: AChangeUserRole) => {
  return await api.patch(`${ADMIN_CHANGE_ROLE}/${formData.id}`, formData);
};