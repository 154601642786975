import { DashboardHeader } from '../../components/molecules';
import { BrokenAdd } from '../../assets/Icons';
import { columns } from './data';
import { useState } from 'react';
import { TableCON, TableHeaderCON } from './style';
import { Container, CustomTable, Spacer } from '../../components/atoms';
import { useQuery } from 'react-query';
import { AddLogistics } from './AddLogistics';
import { getLogistics } from '../../network/services/admin/logistics';

export const LogisticsList = () => {
  const [isLogistics, setLogistics] = useState<boolean>(false);
  const { data, isFetchedAfterMount } = useQuery(['admin_logistics'], getLogistics);
  const categories = data?.data || [];

  const onLogisticsModalClose = () => {
    setLogistics(false);
  };
  const onLogisticsModalOpen = () => {
    setLogistics(true);
  };

  return (
    <Container>
      <DashboardHeader
        title="Logistics"
        buttonPlaceholder="Manage Logistics"
        onClick={onLogisticsModalOpen}
        Icon={BrokenAdd}
      />

      <Spacer height="48px" />
      <TableCON>
        <TableHeaderCON>
          <h3>Logistics List</h3>
        </TableHeaderCON>

        <Container>
          <CustomTable isLoading={isFetchedAfterMount} columns={columns} data={categories} />
        </Container>
      </TableCON>

      {isLogistics && <AddLogistics onModalClose={onLogisticsModalClose} />}
    </Container>
  );
};
