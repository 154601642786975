import { SAddUser } from '../../TYPES/api.body';
import { ADD_USER, GET_ALL_PRODUCT, GET_LOCATION, GET_SELLER_ROLES, GET_SELLER_USERS } from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getAllProduct = async () => {
  return await api.get(GET_ALL_PRODUCT);
};

export const getSellerUsers = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, payload] = queryKey;
  const { type } = payload;
  const { data } = await api.get(`${GET_SELLER_USERS}/${type}`);
  return data;
};

export const getRoles = async () => {
  const { data } = await api.get(GET_SELLER_ROLES);
  return data.map((item: { id: number; roleName: string }) => ({ id: item.id, name: item.roleName }));
};

export const getLocation = async () => {
  const { data } = await api.get(GET_LOCATION);
  return data;
};

export const addNewUser = async (formData: SAddUser) => {
  return await api.post(ADD_USER, formData);
};
