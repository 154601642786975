import { useQueryClient } from 'react-query';
import { Button } from '../../../components/atoms';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { verifySeller } from '../../../network/services/admin/users';
import { ActionCON, extraButtonStyles } from '../../style';

export const VerifySellerAction = ({ id }: any) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutationHook(verifySeller, 'VERIFY_SELLER');

  const _verify = async () => {
    try {
      const response = await mutateAsync({ id: id, status: 'true', reason: 'Approved' });
      
      if (response?.data?.status === 'success') {
        queryClient.invalidateQueries('admin_sellers');
      }
    } catch (error: any) {}
  };

  return (
    <ActionCON>
      <Button isLoading={isLoading} onClick={_verify} extraStyles={extraButtonStyles}>
        Verify
      </Button>
    </ActionCON>
  );
};
