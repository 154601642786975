import { RoleActions } from "../../actions/Admin/Role";

export const filter = [
  {
    label: 'Customers',
    value: 'All'
  },
  {
    label: 'Sub Admins',
    value: 'Sub Admins'
  },

  {
    label: 'Accountants',
    value: 'Accountants'
  }
];

export const columns = [
  {
    id: 1,
    field: 'roleName',
    headerName: 'Name',
    flex: 2
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => <RoleActions role={row} />
  }
];
