import { Route, Routes } from 'react-router-dom';
import { Spacer } from '../../components/atoms';
import {
  AddProduct,
  ProductList,
  OrderList,
  CustomerSupport,
  Delivery,
  UserList,
  FulfilmentList,
  LoanList,
  LoanSettings,
  Settings,
  Dashboard,
  CategoryList,
  ProductDetails,
  LogisticsList
} from '../../pages';
import {
  ADD_PRODUCT,
  PRODUCTS,
  ORDERS,
  CUSTOMERSUPPORT,
  DELIVERIES,
  USERS,
  FUFILLMENT,
  LOANS,
  LOAN_SETTINGS,
  SETTINGS,
  DASHBOARD,
  CATEGORIES,
  ROLES,
  PRODUCT_DETAILS,
  LOGISTICS,
  CUSTOMERSUPPORT_CHAT,
  SUBSCRIPTIONS,
  SHOPPING_ASSISTANT,
  MANAGE_SHOPPING_ASSISTANT,
} from '../routes';
import { Container, MainGridCon } from '../styles';
import { RoleList } from '../../pages/Roles';
import CustomerSupportChat from '../../pages/CustomerSupport/Chat';
import Subscriptions from '../../pages/Subscriptions';
import ShoppingAssistant from '../../pages/ShoppingAssistant';
import ManageShoppingAssistant from '../../pages/ShoppingAssistant/ManageShoppingAssistant';

const DashboardRoutes = () => {
  // const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Container>
      <MainGridCon>
        <Routes>
          <Route path={`${DASHBOARD}`} element={<Dashboard />} />
          <Route path={CATEGORIES} element={<CategoryList />} />
          <Route path={PRODUCTS} element={<ProductList />} />
          <Route path={ADD_PRODUCT} element={<AddProduct />} />
          <Route path={ROLES} element={<RoleList />} />
          <Route path={ORDERS} element={<OrderList />} />
          <Route path={`${PRODUCT_DETAILS}/:id`} element={<ProductDetails />} />
          <Route path={CUSTOMERSUPPORT} element={<CustomerSupport />} />
          <Route path={CUSTOMERSUPPORT_CHAT} element={<CustomerSupportChat />} />
          <Route path={DELIVERIES} element={<Delivery />} />
          <Route path={SUBSCRIPTIONS} element={<Subscriptions />} />
          <Route path={SHOPPING_ASSISTANT} element={<ShoppingAssistant />} />
          <Route path={MANAGE_SHOPPING_ASSISTANT} element={<ManageShoppingAssistant />} />
          <Route path={USERS} element={<UserList />} />
          <Route path={FUFILLMENT} element={<FulfilmentList />} />
          <Route path={LOANS} element={<LoanList />} />
          <Route path={LOAN_SETTINGS} element={<LoanSettings />} />
          {/* <Route path={SETTINGS} element={<Settings />} /> */}
          <Route path={LOGISTICS} element={<LogisticsList />} />

        </Routes>
      </MainGridCon>
      <Spacer height={100} />
    </Container>
  );
};

export default DashboardRoutes;
