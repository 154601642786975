import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const CustomerSupportChatCON = styled('div')`
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const CustomerSupportTableCON = styled('div')`
  background: ${COLORS.White};
`;

export const ChatCON = styled('div')`
  display: flex;
  max-width: 798px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px 20px;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

export const CustomerChatHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 1rem;
  }

  .customer-details {
    display: flex;
    align-items: center;
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const CustomerChatHeaderAvatar = styled('div')`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: url('https://avatars.jakerunzer.com/green%20black');
  background-size: cover;
`;

export const CustomerChatHeaderName = styled('div')`
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 19px;
    color: #000000;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #000000;
  }
`;

export const CustomerChatProduct = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f9f9;

  .more-details-icon {
    align-self: flex-start;
    justify-self: right;
  }
`;

export const CustomerChatProductInfoCON = styled('div')`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 3rem;
  }
`;

export const CustomerChatProductImage = styled('img')`
  width: 100px;
  height: 100px;
  border-radius: 10px;
`;

export const CustomerChatProductInfo = styled('div')`
  color: #000000;
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  .product-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
  }
  .order-id {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
  .order-date {
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
  }
`;

export const CustomerChatMessages = styled('div')`
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  max-height: 250px;
  overflow-y: auto;
`;

export const ChatBubbleCON = styled('p')<{ isAdmin?: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ isAdmin }) =>
    isAdmin
      ? `
  align-self: flex-end;
  background-color: #1ab66e;
  color: #ffffff;
  padding: 10px;
  width: 350px;
  max-width: 450px;
  border-radius: 10px 10px 0px 10px;
`
      : `
  align-self: flex-start;
  background-color: #e0e4f6;
  padding: 10px;
  width: 350px;
  max-width: 450px;
  border-radius: 10px 10px 10px 0px;
`}
  time {
    align-self: flex-end;
    font-size: 12px;
  }

`;

export const MarkChatClosedButtonExtraStyles = {
  width: '12rem',
  borderRadius: '7px',
}