import { ChangeEvent } from 'react';
import { DashboardHeader, TabPanel, TopHeaderCard } from '../../components/molecules';
import {
  loanRequestColumn,
  approvedLoanColumn,
  // disbursedColumn,
  // loans,
  filter,
} from './data';
import { CustomAuthTab, CustomAuthTabs } from './style';
import { useState } from 'react';
import { TableCON, TableHeaderCON } from './style';
import { SettingGearIcon, BoxBlueAddIcon, BoxGreenIcon } from '../../assets/Icons';
import { FILTER_KEYS } from '../../store/keys';
import { Container, CustomTable, SelectField, Spacer } from '../../components/atoms';
import { loanFilter } from '../../store/atoms/loanAtom';
import { useRecoilState } from 'recoil';
import { LOAN_SETTINGS } from '../../navigation/routes';
import { get_loans, get_loans_analytics } from '../../network/services/admin/loan';
import { useQuery } from 'react-query';
import ViewLoan from './ViewLoan';

export const LoanList = () => {
  const [value, setValue] = useState(0);
  const [loan, loanSet] = useState<boolean>(false);
  const [loan_id, loanIDSet] = useState<string>('');
  const [loanFilterState, setloanFilterState] = useRecoilState(loanFilter);
  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setloanFilterState(e.target.value);
  };

  const { data } = useQuery('loans', get_loans);
  const { data: analytics } = useQuery('loans_analytics', get_loans_analytics);
  // const { data: disbursed } = useQuery('loans_disbursed', get_loan_disbursed);

  const requestLoanData = data?.data?.filter((item: any) => item?.status?.toLowerCase() === 'pending') || [];
  const approvedLoanData = data?.data?.filter((item: any) => item?.status?.toLowerCase() === 'approved' || item?.status?.toLowerCase() !== 'pending' || item?.status?.toLowerCase() === 'cancelled') || [];

  console.log('requestLoanData', requestLoanData);
  console.log('approvedLoanData', approvedLoanData);

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { LOAN_APPLICATION, LOAN_DISBURSED } = FILTER_KEYS;

  const topCardData = [
    {
      title: 'Loans Applications',
      total: analytics?.data?.pending ?? 0,
      // Icon: BrokenAdd,
      Icon: BoxBlueAddIcon,
      iconBg: '#EFF1FB'
    },
    {
      title: 'Loans Disbursed',
      total: analytics?.data?.approved ?? 0,
      Icon: BoxGreenIcon,
      iconBg: '#F5FBEF'
    },
  ];

  return (
    <Container>
      <DashboardHeader
        title="BNPL"
        route={`/admin${LOAN_SETTINGS}`}
        buttonPlaceholder="Manage Loan policy"
        Icon={SettingGearIcon}
      />
      <TopHeaderCard gap="40px" data={topCardData} />
      <Spacer height="48px" />
      <TableCON>
        <TableHeaderCON>
          {loanFilterState.includes(LOAN_APPLICATION) ? (
            <h3>BNPL Application</h3>
          ) : (
            loanFilterState.includes(LOAN_DISBURSED) && <h3>Loans Disbursed</h3>
          )}

          <SelectField handleChange={handleFilterChange} options={filter} value={loanFilterState} />
        </TableHeaderCON>

        <Container>
          <CustomAuthTabs value={value} onChange={handleTabChange}>
            <CustomAuthTab label="Request" {...a11yProps(0)} />
            <CustomAuthTab label="Approved" {...a11yProps(1)} />
          </CustomAuthTabs>
          <TabPanel index={0} value={value}>
            <CustomTable
              columns={loanRequestColumn}
              data={requestLoanData}
            />
          </TabPanel>
          <TabPanel index={1} value={value}>
            <CustomTable
              columns={approvedLoanColumn}
              data={approvedLoanData}
            />
          </TabPanel>
        </Container>
      </TableCON>
      {loan && loan_id && (
        <ViewLoan
          loan_id={loan_id}
          onModalClose={() => {
            loanSet(false);
            loanIDSet('');
          }}
        />
      )}
    </Container>
  );
};
