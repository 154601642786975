import { styled, Box, Select } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const InputCon = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0.5rem 0;
  }
`;

export const FormCON = styled(Box)`
  padding: 50px 0px;
`;

export const extraStylesInput = {
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  width: '100%',
  borderRadius: '16px',
  border: '1px solid #E6E6E6',
  height: '56px',
  opacity: '0.8'
};

export const extraStylesCheckbox = {
  alignItems: 'start',
  padding: '12px',
  gap: '10px',
  width: '100%',
  borderRadius: '16px',
  border: '1px solid #E6E6E6',
  height: '24px',
  opacity: '0.8'
};

export const CustomSelect = styled(Select)({
  width: '100%',
  height: '56px',
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  borderRadius: '16px',
  border: '1px solid #E6E6E6',
  opacity: '0.8',
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    // border: '1px solid #dbdce9',
    fontFamily: 'Outfit'
  }
});

export const UploadImageCon = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  div {
    border: 1px solid ${COLORS.MainBlue};
    border-radius: 5px;
    background-color: #e6eaf8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    gap: 30px;
    cursor: pointer;

    p {
      color: ${COLORS.MainBlue};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0.5rem 0;
  }
`;