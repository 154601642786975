import { Action, BrokenEyeIcon, BrokenGclose } from '../../../assets/Icons';
import { CustomPopover } from '../../../components/atoms';
import { ActionCON } from '../../style';
// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { useQueryClient } from 'react-query';
import { deleteCategory } from '../../../network/services/admin/categories';
import { UpdateCategory } from '../../../pages/Categories/Details';

export const CategoryActions = ({ category }: any) => {
  const [value, setValue] = useState<HTMLElement | null>(null);
  const queryClient = useQueryClient();

  const [updateCategory, setUpdateCategory] = useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const _update = () => {
    setUpdateCategory(true);
  };

  const { mutateAsync } = useMutationHook(deleteCategory);

  const _delete = async (id: number) => {
    try {
      await mutateAsync(id);
      queryClient.invalidateQueries('admin_categories');
    } catch (error: any) {}
  };

  const options = [
    {
      label: 'Update',
      value: 'Update',
      Icon: BrokenEyeIcon,
      handleAction: _update
    },

    {
      label: 'Delete',
      value: 'Delete',
      Icon: BrokenGclose,
      color: '#D90429',
      handleAction: _delete
    }
  ];

  return (
    <div>
      <ActionCON aria-describedby="category-actions" onClick={event => handleOpen(event)}>
        <Action />
        <CustomPopover item={category.id} id="category-actions" open={value} options={options} />
      </ActionCON>

      {updateCategory && <UpdateCategory category={category} onModalClose={() => setUpdateCategory(false)} />}
    </div>
  );
};
