import { useState } from 'react';
import { useQuery } from 'react-query';
import { Container, CustomTable } from '../../components/atoms';
import { TabPanel } from '../../components/molecules';
import { getBusinesses } from '../../network/services/admin/users';
// import { getAllAdminUsers, getBusinesses } from '../../network/services/admin/users';
// import { unverifiedBusinessColumn } from './data';
import { unverifiedBusinessColumn, verifiedBusinessColumn } from './data';
import { CustomAuthTab, CustomAuthTabs } from './style';
type AdminUsersProps = {
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
};

export const AdminBusinesses = ({ handleTabChange, value }: AdminUsersProps) => {
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;
  const { data, isFetchedAfterMount } = useQuery(['admin_business', { page: page - 1, size: pageSize }], getBusinesses);
  const business = data?.data || [];

  const verifiedBusiness = business.filter((seller: { status: string }) => seller.status === 'true');
  const unverifiedBusiness = business.filter((seller: { status: string }) => seller.status === 'false');

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <Container>
      <CustomAuthTabs value={value} onChange={handleTabChange}>
        <CustomAuthTab label="Verified" {...a11yProps(0)} />
        <CustomAuthTab label="Unverified" {...a11yProps(1)} />
      </CustomAuthTabs>
      <TabPanel index={0} value={value}>
        <CustomTable
          isLoading={isFetchedAfterMount}
          columns={verifiedBusinessColumn}
          data={verifiedBusiness}
          totalItems={data?.totalElements ?? 0}
          totalPages={data?.totalPages ?? 0}
          currentPage={page}
          setCurrentPage={setPage}
        />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <CustomTable
          isLoading={isFetchedAfterMount}
          columns={unverifiedBusinessColumn}
          data={unverifiedBusiness}
          totalItems={data?.totalElements ?? 0}
          totalPages={data?.totalPages ?? 0}
          currentPage={page}
          setCurrentPage={setPage}
        />
      </TabPanel>

      {/* <CustomTable isLoading={isFetchedAfterMount} columns={unverifiedBusinessColumn} data={business} /> */}
    </Container>
  );
};
