import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { AdduserCon, CustomSelect, InputCon, extraStylesInput } from './style';
import { useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { DropdownIcon2 } from '../../../assets/Icons';
import { getLocation, getRoles } from '../../../network/services/users';
// import { addNewUser, getLocation, getRoles } from '../../../network/services/users';
import { useQuery, useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { adminAddNewUser } from '../../../network/services/admin/users';
import { validators } from '../../../utils/validator';

type AddUserprops = {
  onModalClose: () => void;
};

const INITIAL_STATE = {
  email: '',
  userRoleId: 1,
  locationId: 7
};

export const AdminAddUser = ({ onModalClose }: AddUserprops) => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [formErrors, setFormErrors] = useState(INITIAL_STATE);
  const { data } = useQuery('user_roles', getRoles);
  const roles = data || [];
  const { data: locationData } = useQuery('location', getLocation);
  const locations = locationData || [];
  const queryClient = useQueryClient();
  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };
  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const error = validators[name](value);
      setFormErrors({
        ...formErrors,
        [name]: error as string
      });
    }
    setFormData(formData => ({ ...formData, [name]: value }));
  };
  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const { mutateAsync, isLoading } = useMutationHook(adminAddNewUser, 'ADD_NEW_USER');

  const _createUser = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const { data } = await mutateAsync(formData);
      if (data.status === 'success') {
        queryClient.invalidateQueries('seller_users');
        setFormData(INITIAL_STATE);
        onModalClose();
      }
    } catch (error: any) {}
  };

  const disabled = !formData.email;
  return (
    <Modal title="Add User" maxWidth="500px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Email</span>
          <InputField extraStyles={extraStylesInput} onChange={onInputChange} name="email" error={formErrors.email} />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Role </span>
          <CustomSelect
            IconComponent={DropdownIcon2}
            name="userRoleId"
            value={formData.userRoleId}
            onChange={onSelectChange}
            onClick={selectClick}>
            {roles.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <span>Location </span>
          <CustomSelect
            IconComponent={DropdownIcon2}
            name="locationId"
            label="Please select Location"
            value={formData.locationId}
            onChange={onSelectChange}
            onClick={selectClick}>
            {locations.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCon>

        <Spacer height="52px" />

        <Button onClick={_createUser} isLoading={isLoading} disabled={disabled}>
          Add
        </Button>
      </AdduserCon>
    </Modal>
  );
};
