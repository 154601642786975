import { Button } from '../../components/atoms';
import { ComplaintBox, StatusCON, UserDetailsCON, UserDetailsContent } from './style';
import { ActionCON, extraSubmitBtnStyles } from '../style';
import { Modal } from '../../components/molecules';
import { GreenDot } from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { OrderDetailsResponse, UserChatsResponse } from '../../TYPES/api.body';
import { adminGetOrderDetails } from '../../network/services/admin/order';
import { QUERY_KEYS } from '../../store/keys';
import dayjs from 'dayjs';

interface SupportActionProps {
  extraButtonStyles: Record<string, string>;
  rowData?: UserChatsResponse;
}

export const SupportAction = ({ extraButtonStyles, rowData }: SupportActionProps) => {
  const [value, setValue] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetailsResponse>();
  const nonOrderRelated = !rowData?.orderRef.startsWith('#') && rowData?.orderRef.includes('non-order');
  const orderRef = !nonOrderRelated ? rowData?.orderRef : null;
  const orderRefString = orderRef?.startsWith('#') ? orderRef?.replace(/^#/, '%23') : orderRef;

  useQuery({
    queryKey: [QUERY_KEYS.ORDER_DETAILS, orderRefString],
    queryFn: adminGetOrderDetails,
    onSuccess: ({ data }) => {
      setOrderDetails(data);
    }
  });

  const userId = rowData?.nickName;
  const fullName = rowData?.fullName;
  const navigate = useNavigate();
  const handleClose = () => {
    setValue(false);
  };

  const handleReply = () => {
    let _orderRef = null;
    if (typeof orderRef === null) {
      _orderRef = 'Non-order related chat';
    }
    if (typeof orderRef === 'string') {
      _orderRef = orderRef?.startsWith('#') ? orderRef.slice(1) : orderRef;
    }

    navigate(`chat?orderRef=${_orderRef}&userId=${userId}&fullName=${fullName}`);
  };

  return (
    <ActionCON>
      <Button onClick={() => setValue(true)} extraStyles={extraButtonStyles}>
        View
      </Button>
      {value && (
        <Modal maxWidth="400px" title="Dispute Details" onModalClose={handleClose}>
          <UserDetailsCON>
            <UserDetailsContent>
              <p className="label">Email</p>
              <p className="value">{userId}</p>
            </UserDetailsContent>
            <UserDetailsContent>
              <p className="label">Order Date</p>
              <p className="value">
                {orderDetails?.orderDate ? dayjs(orderDetails?.orderDate).format('DD/MM/YY, hh:mm a') : ''}
              </p>
            </UserDetailsContent>
            <UserDetailsContent>
              <p className="label"> Status</p>
              <StatusCON status="Pending">
                <GreenDot /> <p>{orderDetails?.status ?? ''}</p>
              </StatusCON>
            </UserDetailsContent>
            <ComplaintBox>
              <p className="label">Complaint</p>
              <div>
                <p>{rowData?.complaint}</p>
              </div>
            </ComplaintBox>
            <UserDetailsContent>
              <p className="label">Order ID</p>
              <p className="value">{orderRef}</p>
            </UserDetailsContent>
            <UserDetailsContent>
              <p className="label">Number of Item</p>
              <p className="value">{orderDetails?.cartSize ?? ''}</p>
            </UserDetailsContent>
            {/* <UserDetailsContent>
              <p className="label">Delivery Type</p>
              <p className="value">Pickup</p>
            </UserDetailsContent> */}
            <UserDetailsContent>
              <p className="label">Price</p>
              <p className="value">{orderDetails?.totalAmount?.toLocaleString('en') ?? ''}</p>
            </UserDetailsContent>
            {/* <UserDetailsContent>
              <p className="label"> Status</p>
              <StatusCON status="Pending">
                <GreenDot /> <p>Pending</p>
              </StatusCON>
            </UserDetailsContent> */}
            <Button extraStyles={extraSubmitBtnStyles} onClick={handleReply}>
              Reply Customer
            </Button>
          </UserDetailsCON>
        </Modal>
      )}
    </ActionCON>
  );
};
