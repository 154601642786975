import { ResponseSuccess } from '../../../TYPES/api.body';
import { ADD_CATEGORY, ADD_SUB_CATEGORY, ADMIN_DELETE_CATEGORY, ADMIN_UPDATE_CATEGORY, GET_CATEGORIES } from '../../config/endpoints';
import api from '../../config/setAuthHeader';

export const getCategories = async () => {
  const { data } = await api.get(`${GET_CATEGORIES}`);
  return data;
};

export const addCategory = async (formData: FormData) => {
  const { data } = await api.post(ADD_CATEGORY, formData);
  return data;
};

export const addSubCategory = async (formData: FormData): Promise<ResponseSuccess> => {
  const { data } = await api.post(ADD_SUB_CATEGORY, formData);
  return data;
}

interface UpdateCategoryRequestType {
  formData: FormData;
  categoryId: number;
}

export const adminUpdateCategory = async (payload: UpdateCategoryRequestType) => {
  const { categoryId, formData } = payload;
  return await api.put(`${ADMIN_UPDATE_CATEGORY}/${categoryId}`, formData);
};

export const deleteCategory = async (category: number) => {
  const { data } = await api.delete(`${ADMIN_DELETE_CATEGORY}/${category}`);
  return data;
};
