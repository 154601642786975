import { useMediaQuery, Theme } from '@mui/material';
import { Button } from '../../atoms';
import { DashboardHeaderCON } from './style';
import { useNavigate } from 'react-router-dom';

interface DashboardHeaderProps {
  Icon?: any;
  route?: string;
  onClick?: () => void;
  title: string;
  buttonPlaceholder?: string;
  secondaryOnClick?: () => void;
  secondaryButtonPlaceholder?: string;
}

export function DashboardHeader(props: DashboardHeaderProps) {
  const { title, buttonPlaceholder, Icon, route = '', onClick, secondaryOnClick, secondaryButtonPlaceholder } = props;

  const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const navigate = useNavigate();
  return (
    <DashboardHeaderCON>
      {!md && <h2>{title}</h2>}
      <div className="content">
        {md && <h2>{title}</h2>}
        <div className='buttons'>
          {!!buttonPlaceholder &&
            (route?.length > 0 ? (
              <Button onClick={() => navigate(`${route}`)}>
                {Icon && <Icon />}
                {buttonPlaceholder}
              </Button>
            ) : (
              <Button onClick={onClick}>
                {Icon && <Icon />}
                {buttonPlaceholder}
              </Button>
            ))}
          {!!secondaryButtonPlaceholder && (
            <Button onClick={secondaryOnClick}>
              {Icon && <Icon />} {secondaryButtonPlaceholder}
            </Button>
          )}
        </div>
      </div>
    </DashboardHeaderCON>
  );
}
