import { ResponseSuccess, UserChatsResponse } from '../../../TYPES/api.body';
import { ChatMessage } from '../../../hooks/useWebSocketChat';
import { ADMIN_GET_PREVIOUS_MESSAGES, ADMIN_GET_USERS_CHAT } from '../../config/endpoints';
import api from '../../config/setAuthHeader';

export const getAdminPreviousChatMessages = async ({ queryKey }: any): Promise<ChatMessage[]> => {
  const [_, payload] = queryKey;
  const { nickname, userId } = payload;
  const { data } = await api.get(`${ADMIN_GET_PREVIOUS_MESSAGES}/${nickname}/${userId}`);
  return data;
};

export const getUsersChatWithAdmin = async (): Promise<UserChatsResponse[]> => {
  const { data } = await api.get(`${ADMIN_GET_USERS_CHAT}`);
  return data;
};
