import { atom } from 'recoil';
import { STORE_KEYS } from '../keys';

// export const actionPopover = atom<HTMLElement | null>({
//   key: STORE_KEYS.ORDER_ACTION_POPOVER,
//   default: null
// });

export const subscriptionsFilter = atom<string>({
  key: STORE_KEYS.SUBSCRIPTION_FILTER,
  default: ''
});
