import { ResponseSuccess } from '../../TYPES/api.body';
import { ResponseContent } from '../../TYPES/response';
import {
  ADMIN_CREATE_SHOPPING_ASSISTANT,
  ADMIN_CREATE_SHOPPING_ASSISTANT_FILE,
  ADMIN_GET_SHOPPING_ASSISTANTS,
  ADMIN_GET_SHOPPING_ASSISTANTS_CONFIG_FILES,
  ADMIN_LINK_ASSISTANT_TO_FILE
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export interface CreateShoppingAssistantType {
  modelName: string; //gpt-3.5-turbo-1106
  instruction: string; // set of well defined instructions for the AI to follow
  tool: string; // retrieval
  assistantName: string; // name of the AI assistant, e.g Customer Shopping Assistant
}

export interface ShoppingAssistant {
  id: string;
  object: 'assistant';
  created_at: number;
  name: string;
  model: string;
  instructions: string;
  file_ids: string[];
  metadata: never;
}

export interface LinkFileToAssistantResponse {
  id: string;
  object: 'assistant';
  created_at: number;
  assistant_id: string;
}

export interface CreateShoppingAssistantPayload {
  modelName: string;
  instruction: string;
  tool: string;
  assistantName: string;
}

export interface CreateShoppingAssistantFilePayload {
  purpose: string;
  file: File;
}

export interface ShoppingAssistantConfigFile {
  id: string;
  object: string;
  bytes: number;
  created_at: number;
  filename: string;
  purpose: string;
  status: string;
}

export const getShoppingAssistants = async (): Promise<ResponseContent<ShoppingAssistant[]>> => {
  const { data } = await api.get(ADMIN_GET_SHOPPING_ASSISTANTS);
  return data;
};

export const getShoppingAssistantsConfigFiles = async (): Promise<ResponseContent<ShoppingAssistantConfigFile[]>> => {
  const { data } = await api.get(ADMIN_GET_SHOPPING_ASSISTANTS_CONFIG_FILES);
  return data;
};

// export const getSubscriptionSubscribers = async (): Promise<ResponseContent<SubscriptionSubcriber[]>> => {
//   const { data } = await api.get(ADMIN_GET_SUBSCRIBERS);
//   return data;
// };

// export const getSubscriptionPackageDetails = async ({
//   queryKey
// }: any): Promise<ResponseContent<SubscriptionPackage>> => {
//   const [_, payload] = queryKey;
//   const { subscriptionId } = payload;
//   const { data } = await api.get(`${ADMIN_GET_SUBSCRIPTION_PACKAGE_DETAILS}/${subscriptionId}`);
//   return data;
// };

export const createShoppingAssistant = async (
  formData: CreateShoppingAssistantPayload
): Promise<ResponseContent<ShoppingAssistant>> => {
  const data = await api.post(ADMIN_CREATE_SHOPPING_ASSISTANT, formData);
  return data;
};

export const createShoppingAssistantFile = async (
  formData: FormData
): Promise<ResponseContent<ShoppingAssistantConfigFile>> => {
  const data = await api.post(ADMIN_CREATE_SHOPPING_ASSISTANT_FILE, formData);
  return data;
};

export const linkFileToAssistant = async (): Promise<ResponseContent<LinkFileToAssistantResponse>> => {
  const data = await api.post(ADMIN_LINK_ASSISTANT_TO_FILE);
  return data;
};
