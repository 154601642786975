// import { useRecoilState } from 'recoil';
import { Action, BrokenEyeIcon, BrokenGclose, CheckIcon } from '../../../assets/Icons';
import { CustomPopover } from '../../../components/atoms';
// import { actionPopover } from '../../../store/atoms/orderAtom';
import { ActionCON } from '../../style';
import { Modal } from '../../../components/molecules';
import { DeclineOrder, OrderDetails } from '../../../pages';
import { useState } from 'react';
import { TrackStatus } from '../../../pages/Order/TrackStatus';

export const AdminOrderActions = ({ order, status }: any) => {
  const [value, setValue] = useState<HTMLElement | null>(null);
  const [isView, setView] = useState<boolean>(false);
  const [isDecline, setDecline] = useState<boolean>(false);
  const [isTrackStatus, setTrackStatus] = useState<boolean>(false);

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const handleModalClose = () => {
    setView(false);
  };

  const options = [
    {
      label: 'View details',
      value: 'View details',
      Icon: BrokenEyeIcon,
      handleAction: () => setView(true)
    },

    {
      label: 'Track Status',
      value: 'Track Status',
      Icon: CheckIcon,
      color: '#27AE60',
      handleAction: () => setTrackStatus(true)
    },

    {
      label: 'Decline',
      value: 'Decline',
      Icon: BrokenGclose,
      color: '#D90429',
      handleAction: () => setDecline(true)
    }
  ];
  return (
    <div>
      <ActionCON aria-describedby="order-action" onClick={event => handleOpen(event)}>
        <Action />
        <CustomPopover item={order} id="order-action" open={value} options={options} />
      </ActionCON>

      {isView && (
        <Modal title="Order Details" maxWidth="500px" onModalClose={handleModalClose}>
          <OrderDetails id={order} />
        </Modal>
      )}

      {isDecline && (
        <Modal title="Decline" maxWidth="400px" onModalClose={() => setDecline(false)}>
          <DeclineOrder onModalClose={() => setDecline(false)} id={order} />
        </Modal>
      )}

      {isTrackStatus && (
        <Modal title="Track Status" maxWidth="400px" onModalClose={() => setTrackStatus(false)}>
          <TrackStatus status={status} onModalClose={() => setTrackStatus(false)} id={order} />
        </Modal>
      )}
    </div>
  );
};
