/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProductUpload } from '../../TYPES/api.body';
import {
  DELETE_SELLER_PRODUCT,
  GET_ALL_CATEGORIES,
  GET_ALL_PRODUCT,
  GET_SELLER_PRODUCTS,
  GET_SUB_CATEGORIES,
  MANUAL_PRODUCT_UPLOAD,
  PRODUCT_DETAILS,
  UPDATE_PRODUCT,
  UPLOAD_CSV_PRODUCTS
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getAllProduct = async () => {
  return await api.get(GET_ALL_PRODUCT);
};

export const getSellerProducts = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size } = payload;
  const { data } = await api.get(`${GET_SELLER_PRODUCTS}?page=${page}&size=${size}`);
  return data;
};

export const getAllcategories = async () => {
  const { data } = await api.get(GET_ALL_CATEGORIES);
  return data.data.map((item: { id: number; name: string }) => ({ id: item.id, name: item.name }));
};

export const getSubcategories = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { categoryId } = payload;
  const { data } = await api.get(`${GET_SUB_CATEGORIES}?categoryId=${categoryId}`);
  return data.data.map((item: { id: number; name: string }) => ({ id: item.id, name: item.name }));
};

export const manualProductUpload = async (payload: ProductUpload) => {};

export const getSingleProduct = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { productId } = payload;
  const { data } = await api.get(`${PRODUCT_DETAILS}?product_id=${productId}`);
  return data;
};

export const updateProduct = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { productId } = payload;
  const { data } = await api.patch(`${UPDATE_PRODUCT}/${productId}`);
  return data;
};

export const csvUpload = async (formData: { file: File }) => {
  const { data } = await api.post(UPLOAD_CSV_PRODUCTS, formData);
  return data;
};

export const deleteProduct = async (product: number) => {
  const data = await api.delete(`${DELETE_SELLER_PRODUCT}/${product}`);
  return data;
};
