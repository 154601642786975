import { Box, styled, Tab, Tabs } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const AIAssistantsListCON = styled('div')`
  padding: 20px 10px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 4rem;
`;

export const AIAssistantInfoCard = styled(Box)`
  background-color: ${COLORS.MainBlue};
  color: ${COLORS.White};
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  min-height: 110px;

  &:hover {
    background-color: ${COLORS.MainBlue};
    color: ${COLORS.White};

    svg {
      fill: ${COLORS.White};
    }
  }

  svg {
    fill: ${COLORS.White};
  }

  .assistant-info-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem;
  }

  .assistant-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;

    > *:not(:last-child) {
      margin-right: 1rem;
    }

    svg {
      fill: none;
    }
  }

  .assistant-model-name {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const AIAssistantInfoContent = styled('div')`
  background-color: ${COLORS.White};
  color: ${COLORS.MainBlack};
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
  overflow-y: auto;
  height: 220px;
`;

export const CustomAuthTabs = styled(Tabs)(({ theme }) => ({
  padding: '0px 12px',
  height: '46px',
  gap: '10px',
  minHeight: 'max-content',
  '& .MuiTabs-indicator': {
    height: '4px'
  },
  [theme.breakpoints.down('sm')]: {}
}));


export const CustomAuthTab = styled(Tab)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26.483px',
    textTransform: 'capitalize',
    maxWidth: '60px',
    width: '60px',
    color: '#777777',
    fontStyle: 'normal',
    '&.Mui-selected': {
      color: '#000',
      fontSize: '14px',
      fontWeight: '400',
      textTransform: 'capitalize',
      backgroundColor: `${COLORS.White}`,
      borderRadius: '23px',
      width: '162px',
      [theme.breakpoints.down('sm')]: {
        width: '110px'
      },
      marginTop: '5px',
      height: '36px',
      minHeight: '36px'
    }
  }));