import { styled } from '@mui/material';
import { COLORS } from '../../theme/color';
import { getBackgroundColor, getColor } from '../../utils/statusColors';

export const OrderCON = styled('div')``;

export const OrderTableCON = styled('div')`
  background: ${COLORS.White};
`;

export const TableHeaderCON = styled('div')`
  padding: 32px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OrderStatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) => (status ? getBackgroundColor(status) : '#FFFFFF')};
  color: ${({ status }) => (status ? getColor(status) : '#000000')};
  display: flex;
  padding: 4px 16px;
  font-weight: 500;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;
