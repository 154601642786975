import { useNavigate } from 'react-router-dom';
import { LogoIcon } from '../../../assets/Icons';
import { LogoCon } from './styles';

type logoProps = {
  to?: string;
  noFill?: boolean;
};
export const Logo = ({ to, noFill }: logoProps) => {
  const navigate = useNavigate();

  return (
    <LogoCon onClick={() => navigate(to ? to : 'admin/dashboard')} $noFill={noFill}>
      <LogoIcon className="logo" />
    </LogoCon>
  );
};
