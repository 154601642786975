import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with the customParseFormat plugin
dayjs.extend(customParseFormat);

export const formatDate = (date: string, format: 'full' | 'long' | 'short' = 'short') => {
  const parsedDate = Date.parse(date);

  // Check if the parsedDate is NaN, which indicates an invalid date.
  if (isNaN(parsedDate)) {
    return date; // Return the input date as is.
  }

  return new Intl.DateTimeFormat(undefined, { dateStyle: format }).format(parsedDate);
};

export function convertInformalStringToDate(dateString: string) {
  // The format in the dateString '9 of February, 2024 at 10:48AM'
  const format = 'D [of] MMMM, YYYY [at] h:mmA';
  const newDate = dayjs(dateString, format);
  return newDate.format('DD/MM/YYYY');
}
