import { DashboardHeader } from '../../components/molecules';
import { BrokenAdd } from '../../assets/Icons';
import { columns } from './data';
import { useState } from 'react';
import { TableCON, TableHeaderCON } from './style';
import { Container, CustomTable, Spacer } from '../../components/atoms';
import { useQuery } from 'react-query';
import { AddRole } from './AddRoles';
import { getAdminRoles } from '../../network/services/admin/roles';

export const RoleList = () => {
  const [isAddRole, setAddRole] = useState<boolean>(false);

  const { data, isFetchedAfterMount } = useQuery(['admin_roles'], getAdminRoles);
  const roles = data || [];

  const onAddRoleModalClose = () => {
    setAddRole(false);
  };
  const onAddRoleModalOpen = () => {
    setAddRole(true);
  };

  return (
    <Container>
      <DashboardHeader title="Roles" buttonPlaceholder="Add Role" onClick={onAddRoleModalOpen} Icon={BrokenAdd} />

      <Spacer height="48px" />
      <TableCON>
        <TableHeaderCON>
          <h3>Roles List</h3>
        </TableHeaderCON>

        <Container>
          <CustomTable isLoading={isFetchedAfterMount} columns={columns} data={roles} />
        </Container>
      </TableCON>

      {isAddRole && <AddRole onModalClose={onAddRoleModalClose} />}
    </Container>
  );
};
