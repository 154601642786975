import React from 'react';
import { GreenDot, OrangeDot, RedDot } from '../../../assets/Icons';

interface StatusDotProps {
  status: string;
}

const StatusDot: React.FC<StatusDotProps> = ({ status }) => {
  const lowerCaseStatus = status.toLowerCase();

  if (lowerCaseStatus.includes('pending')) {
    return <OrangeDot />;
  } else if (lowerCaseStatus.includes('completed') || lowerCaseStatus.includes('ongoing')) {
    return <GreenDot />;
  } else if (lowerCaseStatus.includes('rejected') || lowerCaseStatus.includes('cancelled')) {
    return <RedDot />;
  }
  return null;
};

export default StatusDot;
