import { Box } from '@mui/material';
import { DeclineOrderCon, StyledTextArea } from './style';
import { Button, Spacer } from '../../../components/atoms';
import { ChangeEvent, useState } from 'react';
// import { validators } from '../../../utils/validator';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { adminCancelOrder } from '../../../network/services/admin/order';
import { useQueryClient } from 'react-query';

type DeclineOrderProps = {
  id: number;
  onModalClose: () => void;
};

export const DeclineOrder = ({ id, onModalClose }: DeclineOrderProps) => {
  const [formData, setFormData] = useState({ reason: '' });
  const queryClient = useQueryClient();

  const onUpdateFormData = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const { mutateAsync, isLoading } = useMutationHook(adminCancelOrder, 'CANCEL_ORDER');
  const _cancelOrder = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const newFormData = { ...formData, orderId: id };
      const data: any = await mutateAsync(newFormData);
      if (data.status === 'success' || data.status === 'failed') {
        onModalClose();
        queryClient.invalidateQueries('admin_orders_analytics');
        queryClient.invalidateQueries('admin_orders');
      }
    } catch (error: any) {}
  };

  const disabled = !formData.reason;

  return (
    <DeclineOrderCon>
      <Spacer height="40px" />
      <Box>
        <StyledTextArea name="reason" onChange={onUpdateFormData} maxRows={6} minRows={5} />
      </Box>
      <Spacer height="40px" />
      <Button onClick={_cancelOrder} isLoading={isLoading} disabled={disabled}>
        Decline
      </Button>
    </DeclineOrderCon>
  );
};
