import React from 'react';
import { CustomBreadcrumb } from '../../../components/atoms/Breadcrumb';
import { Button, Divider, InputField, SelectField, Spacer } from '../../../components/atoms';

import { LoanSettingsCON, FormCON, InputCon, extraStylesInput, LoanHeaderCON, InterestDiv } from './style';
import { loanApproveOptions } from '../data';
import { useQuery, useQueryClient } from 'react-query';
import { deleteSetting, get_interest, get_setting, updateSetting } from '../../../network/services/admin/loan';
// import { useMutationHook } from '../../../network/mutations/useMutationHook';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Grid } from '@mui/material';
import { AddInterest } from '../misc/AddInterest';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
// import { MenuItem } from '@mui/material';
// import { COMMONTYPES } from '../../../TYPES/event.types';

type IDataState = {
  // id: string; // use this when you want to update and remove it when adding it new
  loanApproval: 'Admin' | 'Automatic'; //Admin or Automatic
  partPaymentPercentage: string; //0.30 for 30 percent
  balancePercentage: string; //0.70 for 70 percent
};

type IAddInterest = string | { id: string; tenure: string; percent: string };

export const LoanSettings = () => {
  const queryClient = useQueryClient();
  const [interest, interestSet] = React.useState<{ show: boolean; data: IAddInterest }>({ data: '', show: false });
  const [loanSettings, dataSet] = React.useState<IDataState>({
    loanApproval: 'Admin',
    partPaymentPercentage: '',
    balancePercentage: ''
  });

  useQuery('loan_setting', get_setting, {
    onSuccess: data => {
      if (data && data?.data && data?.data?.[0]) {
        const set = data?.data?.[0];
        dataSet(prev => ({
          ...prev,
          loanApproval: set?.loanApproval,
          partPaymentPercentage: `${Number(set?.partPaymentPercentage) * 100}`,
          balancePercentage: `${Number(set?.balancePercentage) * 100}`
        }));
      }
    }
  });

  const { data: interests } = useQuery('loan_interest', get_interest);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet(prev => ({ ...prev, [name]: value }));
  };

  const { mutateAsync: mutateAsyncUpdateSetting, isLoading: updateSettingIsLoading } = useMutationHook(
    updateSetting,
    'UPDATE_LOAN_SETTING'
  );
  const { mutateAsync: mutateAsyncDeleteSetting, isLoading: deleteSettingIsLoading } = useMutationHook(
    deleteSetting,
    'DELETE_LOAN_SETTING'
  );

  const _update_loan = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      if (!loanSettings.balancePercentage || !loanSettings.loanApproval || !loanSettings.partPaymentPercentage)
        throw new Error('Please fill all required fields.');

      if (Number(loanSettings.balancePercentage) + Number(loanSettings.partPaymentPercentage) !== 100)
        throw new Error('Balance and part payment percentage must sum up to 100');

      const { data } = await mutateAsyncUpdateSetting({
        loanApproval: loanSettings?.loanApproval,
        partPaymentPercentage: `${Number(loanSettings?.partPaymentPercentage) / 100}`,
        balancePercentage: `${Number(loanSettings?.balancePercentage) / 100}`
      });
      if (data.status === 'success') {
        queryClient.invalidateQueries('loan_setting');
      }
    } catch (error: any) {}
  };

  const _ondelete = async (id: string) => {
    try {
      const { data } = await mutateAsyncDeleteSetting(id);
      if (data.status === 'success') {
        queryClient.invalidateQueries('loan_interest');
      }
    } catch (error: any) {}
  };

  const disabled =
    !loanSettings.balancePercentage ||
    !loanSettings.loanApproval ||
    !loanSettings.partPaymentPercentage ||
    Number(loanSettings.balancePercentage) + Number(loanSettings.partPaymentPercentage) !== 100;

  return (
    <>
      <LoanSettingsCON>
        <LoanHeaderCON>
          <div>
            <CustomBreadcrumb firstLabel="Loans" secondLabel="Loan Settings" />
          </div>
          {/* <Button onClick={_update_loan} isLoading={loading}>
            Update Loan
          </Button> */}
        </LoanHeaderCON>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormCON>
              <h3>BNPL Loan Settings</h3>
              <Divider />

              <Spacer height="26px" />

              <InputCon>
                <span>Loan approval</span>
                <SelectField
                  value={loanSettings.loanApproval}
                  options={loanApproveOptions}
                  handleChange={(e: any) => {
                    const { value } = e.target;
                    dataSet(prev => ({ ...prev, loanApproval: value }));
                  }}
                />
              </InputCon>

              <InputCon>
                <span>Balance Percentage (%)</span>
                <InputField
                  type="text"
                  name="balancePercentage"
                  placeholder="20"
                  value={loanSettings.balancePercentage}
                  onChange={onChange}
                  extraStyles={extraStylesInput}
                  required={true}
                />
              </InputCon>

              <InputCon>
                <span>Upfront Payment Percentage (%)</span>
                <InputField
                  type="text"
                  name="partPaymentPercentage"
                  placeholder="30"
                  value={loanSettings.partPaymentPercentage}
                  onChange={onChange}
                  extraStyles={extraStylesInput}
                  required={true}
                />
              </InputCon>

              <Spacer height="4rem" />
              <Button
                onClick={_update_loan}
                disabled={disabled}
                isLoading={updateSettingIsLoading}>
                Update Loan
              </Button>
            </FormCON>
          </Grid>

          <Grid item xs={6}>
            <FormCON>
              <h3>BNPL Interest</h3>
              <Divider />

              <InterestDiv>
                <h6>Percentage</h6>
                <h6>Tenure</h6>
                <h6>Action</h6>
              </InterestDiv>

              {interests?.data?.length &&
                interests?.status === 'success' &&
                interests?.data?.map((item: any) => (
                  <InterestDiv key={item.id?.toString()}>
                    <p>{item?.percent}</p>
                    <p>{item?.tenure}</p>

                    <div>
                      <span onClick={() => interestSet(prev => ({ ...prev, data: item, show: true }))}>
                        <ModeEditIcon />
                      </span>
                      <span
                        onClick={() => {
                          if (item?.id) _ondelete(item.id);
                        }}>
                        <DeleteForeverIcon style={{ color: 'red' }} />
                      </span>
                    </div>
                  </InterestDiv>
                ))}

              <Spacer height="26px" />

              <Button onClick={() => interestSet(prev => ({ ...prev, show: true, data: '' }))}>Add Interest</Button>

              <Spacer height="4rem" />
            </FormCON>
          </Grid>
        </Grid>
        {interest.show && (
          <AddInterest
            data={interest.data}
            onModalClose={() => interestSet(prev => ({ ...prev, data: '', show: false }))}
          />
        )}
      </LoanSettingsCON>
    </>
  );
};
