import { ShoppingAssistantAction } from '../../../actions/ShoppingAssistant';
import { ShoppingAssistantConfigFileAction } from '../../../actions/ShoppingAssistantConfigFile';

export const shoppingAssistantColumn = [
  {
    id: 1,
    field: 'name',
    headerName: 'Name',
    flex: 2,
    minWidth: 150
  },

  {
    id: 2,
    field: 'model',
    headerName: 'Model',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'metadata',
    headerName: 'Tools',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <div>Shopping</div>;
    }
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 50,
    renderCell: ({ row }: any) => <ShoppingAssistantAction />
  }
];

export const shoppingAssistantConfigFilesColumn = [
  {
    id: 1,
    field: 'purpose',
    headerName: 'Purpose',
    flex: 2,
    minWidth: 150
  },

  {
    id: 2,
    field: 'filename',
    headerName: 'File',
    flex: 1,
    minWidth: 200
  },

  {
    id: 3,
    field: 'status',
    headerName: 'Assistant',
    flex: 2,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <div>Shopping</div>;
    }
  },

  {
    id: 8,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 50,
    renderCell: ({ row }: any) => <ShoppingAssistantConfigFileAction />
  }
];
