import { Route, Routes } from 'react-router-dom';
import Login from '../pages/Auth/Login';
import SellerLogin from '../pages/Auth/Seller/Login';
import DashboardRoutes from './DashboardRoutes/DashboardRoutes';
import ProtectedRoute from './ProtectedRoutes';
import { ADMIN, HOME, OTP, FORGOTPASSWORD, SELLER, SIGN_UP } from './routes';
import ForgotPassword from '../pages/Auth/Seller/ForgotPassword';
import { Otp, SignUp } from '../pages';

export const AppNavigation = () => {
  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}></Route>

        <Route path={`${SELLER}/login`} element={<SellerLogin />} />
        <Route path={HOME} element={<Login />} />
        <Route path={`/seller${FORGOTPASSWORD}`} element={<ForgotPassword />} />
        <Route element={<ProtectedRoute authRequired={true} />}>
          <Route path={`${ADMIN}/*`} element={<DashboardRoutes />} />
        </Route>


        {/* <Route element={<SellerProtectedRoute authRequired={true} />}>
          <Route path={`${SELLER}/*`} element={<SellerRoutes />} />
        </Route> */}


        <Route path={`${SIGN_UP}`} element={<SignUp />} />
        <Route path={OTP} element={<Otp />} />
        <Route element={<ProtectedRoute authRequired />}></Route>
      </Routes>
    </>
  );
};
