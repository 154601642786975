import { Button, InputField, Spacer } from '../../../components/atoms';
import { Modal } from '../../../components/molecules';
import { AdduserCon, InputCon, extraStylesInput } from './style';
import { useRef, useState } from 'react';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { useQueryClient } from 'react-query';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { UploadImageCon } from '../style';
import { UploadIcon } from '../../../assets/Icons';
import { addCategory } from '../../../network/services/admin/categories';

type AddCategoryProps = {
  onModalClose: () => void;
};

const INITIAL_STATE = {
  name: ''
};

export const AddCategory = ({ onModalClose }: AddCategoryProps) => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const logoRef = useRef<HTMLInputElement | null>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const queryClient = useQueryClient();

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const { mutateAsync, isLoading } = useMutationHook(addCategory, 'ADD_CATEGORY');

  const _addCategory = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const payload = new FormData();
    payload.append('request', new Blob([JSON.stringify({ name: formData.name })], { type: 'application/json' }));
    payload.append('category_logo', logo as File);

    try {
      const data  = await mutateAsync(payload);
      if ((data as any).status === 'success') {
        queryClient.invalidateQueries('admin_categories');
        onModalClose();
      }
    } catch (error: any) {}
  };

  const handleFileRead = (e: COMMONTYPES['inputChange']) => {
    const files = e.target.files;
    files && setLogo(files[0]);
  };

  const disabled = !formData.name || !logo?.name.length || isLoading;
  return (
    <Modal title="Add Category" maxWidth="500px" onModalClose={onModalClose}>
      <AdduserCon>
        <InputCon>
          <span>Name</span>
          <InputField extraStyles={extraStylesInput} onChange={onInputChange} name="name" />
        </InputCon>
        <Spacer height="22px" />
        <InputCon>
          <UploadImageCon>
            <span>Logo</span>
            <div onClick={() => logoRef.current?.click()}>
              <p>{logo?.name || 'Upload File'}</p>
              <UploadIcon />
              <input type="file" ref={logoRef} name="logo" onChange={handleFileRead} required={true} />
            </div>
          </UploadImageCon>
        </InputCon>

        <Spacer height="52px" />

        <Button onClick={_addCategory} isLoading={isLoading} disabled={disabled}>
          Add
        </Button>
      </AdduserCon>
    </Modal>
  );
};
